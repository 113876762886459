// Comparison.js
import React, { useState, useEffect, useCallback } from 'react';
import { Container, Form, Row, Col, Button, Nav } from 'react-bootstrap';
import axios from '../../utils/axios';
import ComparisonTable from '../../components/ComparaisonTable/ComparisonTable';
import emptySpace from '../../assets/illo.svg';
import './Comparaison.css';

const Comparison = () => {
  const [simulations, setSimulations] = useState([]);
  const [selectedSimulation1, setSelectedSimulation1] = useState(null);
  const [selectedSimulation2, setSelectedSimulation2] = useState(null);
  const [activeTab, setActiveTab] = useState('order');

  const fetchSimulations = useCallback(async () => {
    try {
      const response = await axios.get('/simulations');
      setSimulations(response.data);
    } catch (error) {
      console.error('Error fetching simulations', error);
    }
  }, []);

  useEffect(() => {
    fetchSimulations();
  }, [fetchSimulations]);

  const handleSelectSimulation1 = (e) => {
    const selectedId = e.target.value;
    const selected = simulations.find(sim => sim.id === parseInt(selectedId, 10));
    setSelectedSimulation1(selected);
  };

  const handleSelectSimulation2 = (e) => {
    const selectedId = e.target.value;
    const selected = simulations.find(sim => sim.id === parseInt(selectedId, 10));
    setSelectedSimulation2(selected);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <Container className="comparison-container">
      <Row>
        <Col>
          <h2>Compare Simulations</h2>
          <p>Choose two simulations to compare their performances.</p>
          <Form className='mb-4'>
            <Row>
              <Col md={6}>
                <Form.Group as={Row} className='select-dropdown'>
                  <Form.Label>Simulation 1</Form.Label>
                  <Col sm={10}>
                    <Form.Control as="select" onChange={handleSelectSimulation1}>
                      <option value="">Select Simulation</option>
                      {simulations.map((sim) => (
                        <option key={sim.id} value={sim.id}>{sim.name}</option>
                      ))}
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group as={Row} className='select-dropdown'>
                  <Form.Label>Simulation 2</Form.Label>
                  <Col sm={10}>
                    <Form.Control as="select" onChange={handleSelectSimulation2} className='form-control-custom'>
                      <option value="">Select Simulation</option>
                      {simulations.map((sim) => (
                        <option key={sim.id} value={sim.id}>{sim.name}</option>
                      ))}
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <Col md={10}>
            <Nav variant="tabs" activeKey={activeTab} className="nav-tabs">
              <Nav.Item>
                <Nav.Link eventKey="order" onClick={() => handleTabChange('order')}>
                  Per Order
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="daily" onClick={() => handleTabChange('daily')}>
                  Per Day
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="monthly" onClick={() => handleTabChange('monthly')}>
                  Per Month
                </Nav.Link>
              </Nav.Item>
            </Nav>

            {selectedSimulation1 && selectedSimulation2 ? (
              <ComparisonTable
                simulation1={selectedSimulation1}
                simulation2={selectedSimulation2}
                activeTab={activeTab}
              />
            ) : (
              <div className="empty-selection">
                <img src={emptySpace} alt="Empty" className="empty-image" />
                <p className="empty-text">Please select two simulations to start comparing.</p>
              </div>
            )}
          </Col>
        </Col>
      </Row>
    </Container>
  );
};

export default Comparison;