import React, { useState } from 'react';
import { Accordion, Card, Form, Row, Col, Button, InputGroup, Tabs, Tab, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faTag,
  faCoins,
  faDollarSign,
  faCalendarDay,
  faCalculator,
  faMoneyCheckDollar,
  faList,
  faMoneyBillTransfer,
  faSquareCheck,
  faTruck,
  faMoneyBillWave,
  faBox,
} from '@fortawesome/free-solid-svg-icons';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useTranslation } from 'react-i18next';
import ToolsFeesTable from '../common/ToolsFeesTable/ToolsFeesTable';
import FixedFeesTable from '../common/FixedFeesTable/FixedFeesTable';
import EmployeeSalariesTable from '../common/EmployeeSalariesTable/EmployeeSalariesTable';

const SimulationForm = ({
  simulation,
  handleChange,
  selectedFeeVariation,
  setSelectedFeeVariation,
  feeVariations,
  handleSelectFeeVariation,
  feeLoading,
  feeSuccess
}) => {
  const { t } = useTranslation();

  // Accordion states
  const [isOpenBasic, setIsOpenBasic] = useState(false);
  const [isOpenPricing, setIsOpenPricing] = useState(true);
  const [isOpenDelivery, setIsOpenDelivery] = useState(true);
  const [isOpenFee, setIsOpenFee] = useState(true);

  const handleToggleBasic = () => setIsOpenBasic((prev) => !prev);
  const handleTogglePricing = () => setIsOpenPricing((prev) => !prev);
  const handleToggleDelivery = () => setIsOpenDelivery((prev) => !prev);
  const handleToggleFee = () => setIsOpenFee((prev) => !prev);

  return (
    <Form className="p-8">
      {/* Basic Details Accordion */}
      <Accordion defaultActiveKey="0">
        <Card className="mb-3 custom-card">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <h5 className="mb-0">{t('SIMULATION.BASIC_DETAILS')}</h5>
            <Button variant="link" onClick={handleToggleBasic} aria-expanded={isOpenBasic}>
              <FontAwesomeIcon icon={isOpenBasic ? faChevronUp : faChevronDown} />
            </Button>
          </Card.Header>
          <Accordion.Collapse eventKey="0" in={isOpenBasic}>
            <Card.Body>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group controlId="name" className="form-group-custom">
                    <Form.Label>{t('SIMULATION.NAME')}</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        required
                        name="name"
                        value={simulation.name || ''}
                        onChange={handleChange}
                        className="form-control-custom"
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="description" className="form-group-custom">
                    <Form.Label>{t('SIMULATION.DESCRIPTION')}</Form.Label>
                    <InputGroup>
                      <Form.Control
                        as="textarea"
                        name="description"
                        value={simulation.description || ''}
                        onChange={handleChange}
                        className="form-control-custom"
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      {/* Pricing Accordion */}
      <Accordion defaultActiveKey="0">
        <Card className="mb-3 custom-card">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <h5 className="mb-0">{t('SIMULATION.PRICING')}</h5>
            <Button variant="link" onClick={handleTogglePricing} aria-expanded={isOpenPricing}>
              <FontAwesomeIcon icon={isOpenPricing ? faChevronUp : faChevronDown} />
            </Button>
          </Card.Header>
          <Accordion.Collapse eventKey="0" in={isOpenPricing}>
            <Card.Body>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group controlId="productPrice" className="form-group-custom">
                    <Form.Label>
                      <FontAwesomeIcon icon={faTag} /> {t('SIMULATION.PRODUCT_PRICE')}
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="product_price"
                        value={simulation.product_price || 0}
                        onChange={handleChange}
                        className="form-control-custom"
                      />
                      <InputGroup.Text>DZD</InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group controlId="salePrice" className="form-group-custom">
                    <Form.Label>
                      <FontAwesomeIcon icon={faCoins} /> {t('SIMULATION.SALE_PRICE')}
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="sale_price"
                        value={simulation.sale_price || 0}
                        onChange={handleChange}
                        className="form-control-custom"
                      />
                      <InputGroup.Text>DZD</InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="euroPrice" className="form-group-custom">
                    <Form.Label>
                      <FontAwesomeIcon icon={faDollarSign} /> {t('SIMULATION.DOLLAR_PRICE')}
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="euro_price"
                        value={simulation.euro_price || 0}
                        onChange={handleChange}
                        className="form-control-custom"
                      />
                      <InputGroup.Text>DZD</InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group controlId="dailyAdsSpend" className="form-group-custom">
                    <Form.Label>
                      <FontAwesomeIcon icon={faCalendarDay} /> {t('SIMULATION.DOLLAR_SPEND_PER_DAY')}
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="daily_ads_spend"
                        value={simulation.daily_ads_spend || 0}
                        onChange={handleChange}
                        className="form-control-custom"
                      />
                      <InputGroup.Text>$</InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="calculationBasis" className="form-group-custom">
                    <Form.Label>
                      <FontAwesomeIcon icon={faCalculator} /> {t('SIMULATION.CALCULATION_BASED_ON')}
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="calculate_based_on"
                      value={simulation.calculate_based_on || 'Enter Cost Manually'}
                      onChange={handleChange}
                      className="form-control-custom custom-select"
                    >
                      <option value="Enter Cost Manually">{t('SIMULATION.ENTER_COST_MANUALLY')}</option>
                      <option value="Enter Orders Number">{t('SIMULATION.ENTER_ORDERS_NUMBER')}</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  {simulation.calculate_based_on === 'Enter Cost Manually' ? (
                    <>
                      <Form.Group controlId="cost" className="form-group-custom">
                        <Form.Label>
                          <FontAwesomeIcon icon={faMoneyCheckDollar} /> {t('SIMULATION.COST')}
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            type="number"
                            name="cost"
                            value={simulation.cost || 0}
                            onChange={handleChange}
                            className="form-control-custom"
                          />
                          <InputGroup.Text>$</InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group controlId="orders" className="form-group-custom">
                        <Form.Label>
                          <FontAwesomeIcon icon={faList} /> {t('SIMULATION.ORDERS')}
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            type="number"
                            name="orders"
                            disabled
                            value={simulation.orders || 0}
                            className="form-control-custom"
                          />
                          <InputGroup.Text>Orders</InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                    </>
                  ) : (
                    <>
                      <Form.Group controlId="costOrder" className="form-group-custom">
                        <Form.Label>
                          <FontAwesomeIcon icon={faMoneyCheckDollar} /> {t('SIMULATION.COST')}
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            type="number"
                            name="cost_per_order"
                            value={simulation.cost || 0}
                            disabled
                            className="form-control-custom"
                          />
                          <InputGroup.Text>$</InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group controlId="orders" className="form-group-custom">
                        <Form.Label>
                          <FontAwesomeIcon icon={faList} /> {t('SIMULATION.ORDERS')}
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            type="number"
                            name="orders"
                            value={simulation.orders || 0}
                            onChange={handleChange}
                            className="form-control-custom"
                          />
                          <InputGroup.Text>Orders</InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                    </>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      {/* Delivery Accordion */}
      <Accordion defaultActiveKey="0">
        <Card className="mb-3 custom-card">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <h5 className="mb-0">{t('SIMULATION.DELIVERY')}</h5>
            <Button variant="link" onClick={handleToggleDelivery} aria-expanded={isOpenDelivery}>
              <FontAwesomeIcon icon={isOpenDelivery ? faChevronUp : faChevronDown} />
            </Button>
          </Card.Header>
          <Accordion.Collapse eventKey="0" in={isOpenDelivery}>
            <Card.Body>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group controlId="delivery_price" className="form-group-custom">
                    <Form.Label>
                      <FontAwesomeIcon icon={faMoneyBillTransfer} /> {t('SIMULATION.DELIVERY_PRICE')}
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="delivery_price"
                        value={simulation.delivery_price || 0}
                        onChange={handleChange}
                        className="form-control-custom"
                      />
                      <InputGroup.Text>DZD</InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group controlId="packaging_fee" className="form-group-custom">
                    <Form.Label>
                      <FontAwesomeIcon icon={faMoneyBillTransfer} /> {t('SIMULATION.PACKAGING_FEE')}
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="packaging_fee"
                        value={simulation.packaging_fee || 0}
                        onChange={handleChange}
                        className="form-control-custom"
                      />
                      <InputGroup.Text>DZD</InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group controlId="confirmation_percentage" className="form-group-custom">
                    <Form.Label>
                      <FontAwesomeIcon icon={faSquareCheck} /> {t('SIMULATION.CONFIRMATION_PERCENTAGE')}
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="confirmation_percentage"
                        value={simulation.confirmation_percentage || 0}
                        onChange={handleChange}
                        min={1}
                        max={100}
                        className="form-control-custom"
                      />
                      <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group controlId="delivery_percentage" className="form-group-custom">
                    <Form.Label>
                      <FontAwesomeIcon icon={faTruck} /> {t('SIMULATION.DELIVERY_PERCENTAGE')}
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="delivery_percentage"
                        value={simulation.delivery_percentage || 0}
                        onChange={handleChange}
                        min={1}
                        max={100}
                        className="form-control-custom"
                      />
                      <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                  {/* <Form.Group controlId="confirmation_percentage" className="form-group-custom">
                    <Form.Label>
                      <FontAwesomeIcon icon={faSquareCheck} /> {t('SIMULATION.CONFIRMATION_PERCENTAGE')}
                    </Form.Label>
                    <Slider
                      min={1}
                      max={100}
                      value={simulation.confirmation_percentage || 0}
                      onChange={(value) => handleSliderChange(value, 'confirmation_percentage')}
                      onChangeCommitted={(e, value) => handleSliderCommit(value, 'confirmation_percentage')}
                      className="slider-custom"
                    />
                    <Form.Text className="form-text-custom">{simulation.confirmation_percentage || 0}%</Form.Text>
                  </Form.Group>
                  <Form.Group controlId="delivery_percentage" className="form-group-custom">
                    <Form.Label>
                      <FontAwesomeIcon icon={faTruck} /> {t('SIMULATION.DELIVERY_PERCENTAGE')}
                    </Form.Label>
                    <Slider
                      min={1}
                      max={100}
                      value={simulation.delivery_percentage || 0}
                      onChange={(value) => handleSliderChange(value, 'delivery_percentage')}
                      onChangeCommitted={(e, value) => handleSliderCommit(value, 'delivery_percentage')}
                      className="slider-custom"
                    />
                    <Form.Text className="form-text-custom">{simulation.delivery_percentage || 0}%</Form.Text>
                  </Form.Group> */}
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="codFee" className="form-group-custom">
                    <Form.Label>
                      <FontAwesomeIcon icon={faMoneyBillWave} /> {t('SIMULATION.COD_FEE')}
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="cod_fee"
                        value={simulation.cod_fee || 0}
                        onChange={handleChange}
                        className="form-control-custom"
                      />
                      <InputGroup.Text>DZD</InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group controlId="returnFee" className="form-group-custom">
                    <Form.Label>
                      <FontAwesomeIcon icon={faBox} /> {t('SIMULATION.RETURN_FEE')}
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="return_fee"
                        value={simulation.return_fee || 0}
                        onChange={handleChange}
                        className="form-control-custom"
                      />
                      <InputGroup.Text>DZD</InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      {/* Fees Accordion */}
      <Accordion defaultActiveKey="0">
        <Card className="mb-3 custom-card">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <h5 className="mb-0">{t('SIMULATION.FEES')}</h5>
            <Button variant="link" onClick={handleToggleFee} aria-expanded={isOpenFee}>
              <FontAwesomeIcon icon={isOpenFee ? faChevronUp : faChevronDown} />
            </Button>
          </Card.Header>
          <Accordion.Collapse eventKey="0" in={isOpenFee}>
            <Card.Body>
              <Row>
              <Row className="align-items-center">
                <Col xs={12} md={8}>
                  <Form.Group controlId="simulationFeeVariation" className="select-dropdown m-2">
                    <Form.Label>{t('SIMULATION.FEE_VARIATION')}</Form.Label>
                    <Form.Control
                      as="select"
                      name="fee_variation_id"
                      value={simulation.fee_variation_id || ''}
                      onChange={handleSelectFeeVariation}
                      className="form-control-custom"
                      disabled={feeLoading}
                    >
                      <option value="">{t('SIMULATION.SELECT_FEE_VARIATION')}</option>
                      {feeVariations.map((fv) => (
                        <option key={fv.id} value={fv.id}>
                          {fv.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={12} md={4} className="d-flex align-items-center">
                  {feeLoading && (
                    <div className="d-flex align-items-center ml-2">
                      <Spinner animation="border" size="sm" className="mr-2" />
                      <span>Calculating...</span>
                    </div>
                  )}
                  {feeSuccess && !feeLoading && (
                    <span className="text-success ml-2">Success</span>
                  )}
                </Col>
              </Row>
                {selectedFeeVariation && (
                  <Row className="m-2">
                    <Tabs defaultActiveKey="employeeSalaries">
                      <Tab eventKey="employeeSalaries" title="Employee Salaries">
                        <EmployeeSalariesTable
                          fees={selectedFeeVariation.EmployeeSalaries || []}
                          setVariation={setSelectedFeeVariation}
                          variation={selectedFeeVariation}
                          allowEdit={false}
                        />
                      </Tab>
                      <Tab eventKey="fixedFees" title="Fixed Fees">
                        <FixedFeesTable
                          fees={selectedFeeVariation.FixedFees || []}
                          setVariation={setSelectedFeeVariation}
                          variation={selectedFeeVariation}
                          allowEdit={false}
                        />
                      </Tab>
                      <Tab eventKey="toolsFees" title="Tools Fees">
                        <ToolsFeesTable
                          fees={selectedFeeVariation.ToolFees || []}
                          setVariation={setSelectedFeeVariation}
                          variation={selectedFeeVariation}
                          allowEdit={false}
                        />
                      </Tab>
                    </Tabs>
                  </Row>
                )}
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </Form>
  );
};

export default SimulationForm;