import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Alert, InputGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from '../../utils/axios';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showResendLink, setShowResendLink] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');
    setError('');
    try {
      await axios.post('/auth/forgot-password', { email }, {headers: {'Content-Type': 'application/json'}});
      setMessage('Password reset link has been sent to your email. Please check your inbox.');
      setShowResendLink(true);
      setLoading(false);
    } catch (error) {
      setMessage('');
      setError(error.response?.data?.message || 'An error occurred');
      setLoading(false);
    }
  };

  const handleResend = () => {
    // Reset the form state
    setMessage('');
    setError('');
    setShowResendLink(false);
    setEmail('');
  };

  const renderForm = () => (
    <Form onSubmit={handleSubmit}>
      <p>Please enter the email address you used to create your account. We will send you a link to reset your password.</p>
      <Form.Group className="m-3">
        <Form.Label>Email</Form.Label>
        <InputGroup>
        <Form.Control
          type="email"
          name="email"
          value={email}
          onChange={handleChange}
          required
        />
        </InputGroup>
      </Form.Group>
      <Row className="justify-content-between">
        <Col sm="auto">
          <Button variant="secondary" onClick={() => navigate('/')}>
            Back to login
          </Button>
        </Col>
        <Col sm="auto">
          <Button variant="primary" type="submit" disabled={loading}>
            {loading ? 'Sending...' : 'Send Reset Link'}
          </Button>
        </Col>
      </Row>
    </Form>
  );

  return (
    <Container fluid className="d-flex align-items-center justify-content-center min-vh-100">
      <Row className="justify-content-center w-100">
        <Col md={6} lg={4}>
          <div className="form-container p-4 border rounded shadow">
            <h1 className="text-center mb-4">Forgot Password</h1>
            {message && <Alert variant="success">{message}</Alert>}
            {error && <Alert variant="danger">{error}</Alert>}
            {!showResendLink ? (
              renderForm()
            ) : (
              <div>
                <Button variant="link" className="mb-2" onClick={handleResend}>
                  Didn't receive the link? Resend again.
                </Button>
                
                <Row className="justify-content-between">
                  <Col sm="auto">
                    <Button variant="secondary" onClick={() => navigate('/')}>
                      Back to Login
                    </Button>
                   
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPassword;