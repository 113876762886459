import React, { useState, useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { Spinner, Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSubscription } from '../../contexts/SubscriptionContext';

const ProtectedRoute = () => {
  const { t } = useTranslation();
  const { isSubscribed, isTrial, loading, fetchSubscriptionStatus } = useSubscription();
  const [localLoading, setLocalLoading] = useState(true);

  useEffect(() => {
    const checkAuthorization = async () => {
      setLocalLoading(true);
      await fetchSubscriptionStatus();
      setLocalLoading(false);
    };
    checkAuthorization();
  }, [fetchSubscriptionStatus]);

  if (loading || localLoading) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Row>
          <Col className="text-center">
            <Spinner animation="border" className="loading-spinner" role="status">
              <span className="visually-hidden">{t('GENERAL.LOADING')}</span>
            </Spinner>
            <div>{t('GENERAL.LOADING')}</div>
          </Col>
        </Row>
      </Container>
    );
  }

  return (isSubscribed || isTrial) ? <Outlet /> : <Navigate to="/dashboard/plans" />;
};

export default ProtectedRoute;