import React, { useState } from 'react';
import axios from '../../utils/axios';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container, Row, Col, Alert, InputGroup, Dropdown } from 'react-bootstrap';
import { FaGlobe } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import Avatar1 from '../../assets/avatar1.jpg';
import Avatar2 from '../../assets/avatar2.jpg';
import LogoVertical from '../../assets/Logo Vertical Blanc.png'; // For mobile
import LogoHorizontal from '../../assets/Logo Horizontal 1 Blanc.png'; // For desktop

const languageLabels = {
  en: 'English',
  fr: 'Français',
  ar: 'العربية'
};

const Signup = () => {
  const [form, setForm] = useState({ name: '', email: '', confirmEmail: '', country: '', phoneNumber: '', avatar: 'avatar1', password: '' });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleAvatarChange = (avatar) => {
    setForm({ ...form, avatar });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!form.name) newErrors.name = t('Name is required.');
    if (!form.email) newErrors.email = t('Email is required.');
    if (!form.country) newErrors.country = t('Country is required.');
    if (!form.phoneNumber) newErrors.phoneNumber = t('Phone number is required.');
    if (!form.password) newErrors.password = t('Password is required.');
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const response = await axios.post('/signup', form);
      const messageKey = response.data.messageKey;

      setErrorMessage('');
      setSuccessMessage(t(messageKey));

      navigate('/verify-email');
    } catch (error) {
      const messageKey = error.response?.data?.messageKey || 'signupError';

      if (error.response?.data?.resendLink) {
        setErrorMessage(
          <>
            {t(messageKey)} <br />
            <Button variant="link" id='ResendVerificationEmailSignup' onClick={() => resendVerificationEmail()}>{t('Resend Verification Email')}</Button>
          </>
        );
      } else {
        setErrorMessage(t(messageKey));
      }

      setSuccessMessage('');
    }
  };

  const resendVerificationEmail = async () => {
    try {
      await axios.post('/signup/resend-verification', { email: form.email });
      setSuccessMessage(t('Verification email resent. Please check your inbox.'));
      setErrorMessage('');
    } catch (error) {
      setErrorMessage(t('Failed to resend verification email.'));
      setSuccessMessage('');
    }
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    document.documentElement.lang = lng;
    document.documentElement.dir = lng === 'ar' ? 'rtl' : 'ltr';
  };

  return (
    <div className="login-wrapper">
      <Container fluid className="custom-container">
        <Row className="flex-grow-1 w-100 m-0">
      <Col xs={12} md={6} className="custom-sidebar">
               <div className="logo-container">
                 <img src={LogoVertical} alt="Cod Pricing Logo Vertical" className="logo-img logo-vertical" />
                 <img src={LogoHorizontal} alt="Cod Pricing Logo Horizontal" className="logo-img logo-horizontal" />
               </div>
             </Col>
          <Col xs={12} md={6} className="d-flex align-items-center justify-content-center">
            <div className="custom-form-container">
              <h1 className="text-center mb-4">{t('Create your account')}</h1>
              {successMessage && <Alert variant="success">{successMessage}</Alert>}
              {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Choose Avatar')}</Form.Label>
                  <div className="d-flex justify-content-around">
                    <div className="avatar-container" onClick={() => handleAvatarChange('avatar1')}>
                      <img
                        src={Avatar1}
                        alt="Avatar 1"
                        className={`avatar-img ${form.avatar === 'avatar1' ? 'selected' : ''}`}
                      />
                    </div>
                    <div className="avatar-container" onClick={() => handleAvatarChange('avatar2')}>
                      <img
                        src={Avatar2}
                        alt="Avatar 2"
                        className={`avatar-img ${form.avatar === 'avatar2' ? 'selected' : ''}`}
                      />
                    </div>
                  </div>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>{t('Name')}</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="name"
                      value={form.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                      autoComplete="name"
                    />
                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>{t('Email')}</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="email"
                      name="email"
                      value={form.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                      autoComplete="email"
                    />
                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Row className="mb-3">
                  <Col xs={12} sm={6}>
                    <Form.Group>
                      <Form.Label>{t('Country')}</Form.Label>
                      <InputGroup>
                        <Form.Control
                          as="select"
                          name="country"
                          value={form.country}
                          onChange={handleChange}
                          isInvalid={!!errors.country}
                          className="custom-select"
                        >
                          <option value="">{t('Choose...')}</option>
                          <option value="Algeria">{t('Algeria')}</option>
                          <option value="Morocco">{t('Morocco')}</option>
                          <option value="Tunisia">{t('Tunisia')}</option>
                          <option value="Libya">{t('Libya')}</option>
                          <option value="Other">{t('Other')}</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">{errors.country}</Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={6}>
                    <Form.Group>
                      <Form.Label>{t('Phone Number')}</Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="phoneNumber"
                          value={form.phoneNumber}
                          onChange={handleChange}
                          isInvalid={!!errors.phoneNumber}
                        />
                        <Form.Control.Feedback type="invalid">{errors.phoneNumber}</Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group className="mb-3">
                  <Form.Label>{t('Password')}</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="password"
                      name="password"
                      value={form.password}
                      onChange={handleChange}
                      isInvalid={!!errors.password}
                      className="password-input"
                      autoComplete="current-password"
                    />
                    <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Button variant="primary" type="submit" className="w-100 mb-3 custom-button">
                  {t('Create your account')}
                </Button>

                <div className="text-center mb-3">
                  {t('By continuing, you agree to our')}{' '}
                  <Button
                    variant="link"
                    className="p-0 m-0 small-text"
                    onClick={() => window.open('/privacy-policy-and-terms-of-use', '_blank')}
                  >
                    {t('Privacy Policy and Terms of Use')}
                  </Button>
                </div>
              </Form>

              <div className="text-center mb-3">
                <Button variant="link" className="auth-link" onClick={() => navigate('/')}>
                  {t('Already have an account?')}
                </Button>
              </div>

              <div className="d-flex justify-content-end">
                <Dropdown className="me-3 custom-dropdown">
                  <Dropdown.Toggle variant="light" id="language-dropdown" className="custom-dropdown-toggle">
                    <FaGlobe className="me-1" />
                    {languageLabels[i18n.language]}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {Object.keys(languageLabels).map((lang) => (
                      <Dropdown.Item key={lang} onClick={() => changeLanguage(lang)}>
                        {languageLabels[lang]}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <footer className="footer">
        <p className="footer-text"> 
          © {new Date().getFullYear()} Cod Pricing. {t('All rights reserved.')}
        </p>
      </footer>
    </div>
  );
};

export default Signup;