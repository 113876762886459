import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Card, Badge, Modal, Form, Tabs, Tab } from 'react-bootstrap';
import { FaPlus, FaEdit, FaTrash } from 'react-icons/fa';
import axios from '../../utils/axios';
import './FeeVariationList.css';
import ToolsFeesTable from '../../components/common/ToolsFeesTable/ToolsFeesTable';
import FixedFeesTable from '../../components/common/FixedFeesTable/FixedFeesTable';
import EmployeeCard from '../../components/common/EmployeeCard/EmployeeCard';

const roles = [
  'Manager 1',
  'Manager 2',
  'Manager 3',
  'Confirmation Agent',
  'Media Buyer Agent',
  'Packaging Agent',
  'Other'
];

const FeeVariationList = () => {
  const { t } = useTranslation();
  const [variations, setVariations] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showToolsModal, setShowToolsModal] = useState(false);
  const [showSalaryModal, setShowSalaryModal] = useState(false);
  const [showFixedFeeModal, setShowFixedFeeModal] = useState(false);
  const [isEditModeTools, setIsEditModeTools] = useState(false);
  const [isEditModeSalary, setIsEditModeSalary] = useState(false);
  const [isEditModeFixedFee, setIsEditModeFixedFee] = useState(false);
  const [newVariation, setNewVariation] = useState({ name: '' });
  const [selectedVariation, setSelectedVariation] = useState(null);
  const [selectedTool, setSelectedTool] = useState({ name: '', amount: '', frequency: '' });
  const [selectedSalary, setSelectedSalary] = useState({ name: '', role: '', amount: '' });
  const [selectedFixedFee, setSelectedFixedFee] = useState({ name: '', amount: '' });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVariations = async () => {
      try {
        const response = await axios.get('/fee-variations', { withCredentials: true });
        setVariations(response.data);
        if (response.data.length > 0) {
          setSelectedVariation(response.data[0]);
        }
      } catch (error) {
        console.error(t('FEE_VARIATIONS.ERROR_FETCHING'));
      }
    };

    fetchVariations();
  }, [t]);

  const handleAddVariation = async () => {
    if (!newVariation.name) {
      setError(t('FEE_VARIATIONS.NAME_REQUIRED'));
      return;
    }
    const variationPayload = {
      name: newVariation.name,
    };

    try {
      const response = await axios.post('/fee-variations', variationPayload, { withCredentials: true });
      setVariations([...variations, response.data]);
      setShowAddModal(false);
      navigate(`/dashboard/fees/${response.data.id}`);
    } catch (error) {
      console.error(t('FEE_VARIATIONS.ERROR_ADDING'));
    }
  };

  const countRoles = (employees, role) => {
    return employees.filter(employee => employee.role === role).length;
  };

  const handleDeleteVariation = async () => {
    try {
      await axios.delete(`/fee-variations/${selectedVariation.id}`, { withCredentials: true });
      setVariations(variations.filter(variation => variation.id !== selectedVariation.id));
      setShowDeleteModal(false);
      setSelectedVariation(variations[0] || null);
    } catch (error) {
      console.error(t('FEE_VARIATIONS.ERROR_DELETING'));
    }
  };

  const handleToolsModalSave = async () => {
    if (isEditModeTools) {
      try {
        const response = await axios.put(`/tool-fees/${selectedTool.id}`, selectedTool, { withCredentials: true });
        setSelectedVariation({
          ...selectedVariation,
          ToolFees: selectedVariation.ToolFees.map(fee => fee.id === response.data.id ? response.data : fee)
        });
      } catch (error) {
        console.error(t('FEE_VARIATIONS.ERROR_UPDATING_TOOL_FEE'));
      }
    } else {
      try {
        const response = await axios.post('/tool-fees', { ...selectedTool, fee_variation_id: selectedVariation.id }, { withCredentials: true });
        setSelectedVariation({
          ...selectedVariation,
          ToolFees: [...selectedVariation.ToolFees, response.data]
        });
      } catch (error) {
        console.error(t('FEE_VARIATIONS.ERROR_ADDING_TOOL_FEE'));
      }
    }
    setShowToolsModal(false);
    setSelectedTool({ name: '', amount: '', frequency: '' });
  };
  const handleSalaryModalSave = async () => {
    if (isEditModeSalary) {
      try {
        const response = await axios.put(`/employee-salaries/${selectedSalary.id}`, selectedSalary, { withCredentials: true });
        setSelectedVariation({
          ...selectedVariation,
          EmployeeSalaries: selectedVariation.EmployeeSalaries.map(emp => emp.id === response.data.id ? response.data : emp)
        });
      } catch (error) {
        console.error(t('FEE_VARIATIONS.ERROR_UPDATING_SALARY'));
      }
    } else {
      try {
        const response = await axios.post('/employee-salaries', { ...selectedSalary, fee_variation_id: selectedVariation.id }, { withCredentials: true });
        setSelectedVariation({
          ...selectedVariation,
          EmployeeSalaries: [...selectedVariation.EmployeeSalaries, response.data]
        });
      } catch (error) {
        console.error(t('FEE_VARIATIONS.ERROR_ADDING_SALARY'));
      }
    }
    setShowSalaryModal(false);
    setSelectedSalary({ name: '', role: '', amount: '' });
  };

  const handleEditFixedFee = (fixedFee) => {
    setSelectedFixedFee(fixedFee);
    setIsEditModeFixedFee(true);
    setShowFixedFeeModal(true);
  };

  const handleFixedFeeModalSave = async () => {
    if (isEditModeFixedFee) {
      try {
        const response = await axios.put(`/fixed-fees/${selectedFixedFee.id}`, selectedFixedFee, { withCredentials: true });
        setSelectedVariation({
          ...selectedVariation,
          FixedFees: selectedVariation.FixedFees.map(fee => fee.id === response.data.id ? response.data : fee)
        });
      } catch (error) {
        console.error(t('FEE_VARIATIONS.ERROR_UPDATING_FIXED_FEE'));
      }
    } else {
      try {
        const response = await axios.post('/fixed-fees', { ...selectedFixedFee, fee_variation_id: selectedVariation.id }, { withCredentials: true });
        setSelectedVariation({
          ...selectedVariation,
          FixedFees: [...selectedVariation.FixedFees, response.data]
        });
      } catch (error) {
        console.error(t('FEE_VARIATIONS.ERROR_ADDING_FIXED_FEE'));
      }
    }
    setShowFixedFeeModal(false);
    setSelectedFixedFee({ name: '', amount: '' });
  };

  const handleChange = (e) => {
    setNewVariation({ ...newVariation, [e.target.name]: e.target.value });
    setSelectedTool({ ...selectedTool, [e.target.name]: e.target.value });
    setSelectedSalary({ ...selectedSalary, [e.target.name]: e.target.value });
    setSelectedFixedFee({ ...selectedFixedFee, [e.target.name]: e.target.value });
    setError('');
  };

  const handleCardClick = (variation) => {
    setSelectedVariation(variation);
  };

  return (
    <Container fluid>
      <Row className="my-3">
        <Col>
          <h1>{t('FEE_VARIATIONS.TITLE')}</h1>
        </Col>
        <Col className="text-end">
          <Button variant="primary" onClick={() => setShowAddModal(true)} className="btn-custom">
            <FaPlus /> {t('FEE_VARIATIONS.ADD_FEE_VARIATION')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={7} className='p-2'>
          {variations.map(variation => (
            <Card
              key={variation.id}
              className={`fee-card mb-3 ${selectedVariation?.id === variation.id ? 'selected' : ''}`}
              onClick={() => handleCardClick(variation)}
            >
              <Card.Body className="d-flex justify-content-between align-items-center">
                <div className="row">
                  <Card.Title>{variation.name}</Card.Title>
                  <div className="info">
                    <div className="roles-count">
                      {roles.map(role => (
                        <div key={role} className="role-count">
                          {countRoles(variation?.EmployeeSalaries || [], role) > 0 && (
                            <>
                              <span className="role-name">{t(`roles.${role.toLowerCase().replace(' ', '_')}`)}</span>
                              <Badge className={`count-${role.toLowerCase().replace(' ', '-')}`}>
                                {countRoles(variation?.EmployeeSalaries || [], role)}
                              </Badge>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="action-buttons">
                  <Button variant="link" onClick={() => navigate(`/dashboard/fees/${variation.id}`)} className="btn-icon">
                    <FaEdit className="action-icon-edit" />
                  </Button>
                  {' '}
                  <Button variant="link" onClick={() => { setSelectedVariation(variation); setShowDeleteModal(true); }} className="btn-icon">
                    <FaTrash className="action-icon-delete" />
                  </Button>
                </div>
              </Card.Body>
            </Card>
          ))}
        </Col>
        <Col md={5}>
          {selectedVariation && (
            <Card>
              <Card.Body>
                <h3 className="mb-3">{selectedVariation.name}</h3>
                <div className="scroll-container mb-3">
                  <Row className="flex-nowrap">
                    <Col className="scroll-item">
                      <Card className="sum-card mb-2 gradient-card">
                        <Card.Body>
                          <Card.Text className="text">
                            <div>{t('FEE_VARIATIONS.SUM_OF_TOOLS_FEES')}</div>
                            <p>{Number(selectedVariation.sumOfToolsFees).toFixed(2)} DZD/Month</p>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col className="scroll-item">
                      <Card className="sum-card mb-2 gradient-card">
                        <Card.Body>
                          <Card.Text className="text">
                            <div>{t('FEE_VARIATIONS.SUM_OF_EMPLOYEE_SALARIES')}</div>
                            <p>{Number(selectedVariation.employeeSalary).toFixed(2)} DZD/Month</p>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col className="scroll-item">
                      <Card className="sum-card mb-2 gradient-card">
                        <Card.Body>
                          <Card.Text className="text">
                            <div>{t('FEE_VARIATIONS.SUM_OF_FIXED_FEES')}</div>
                            <p>{Number(selectedVariation.sumOfFixedFees).toFixed(2)} DZD/Month</p>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </div>
                <Tabs defaultActiveKey="employeeSalary" id="fees-tab-example">
                  <Tab eventKey="employeeSalary" title={t('FEE_VARIATIONS.EMPLOYEE_SALARIES')}>
                    <Row className="mt-3">
                      {selectedVariation.EmployeeSalaries && selectedVariation.EmployeeSalaries.length > 0 ? selectedVariation.EmployeeSalaries.map((employee) => (
                        <Col key={employee.id} xs={12}>
                          <EmployeeCard
                            employee={employee}
                            allowEdit={false}
                          />
                        </Col>
                      )) : (
                        <p>{t('FEE_VARIATIONS.NO_EMPLOYEE_SALARIES')}</p>
                      )}
                    </Row>
                    <Card.Footer className="text-center">
                      <Button
                        variant="primary"
                        onClick={() => {
                          setSelectedSalary({ name: '', role: '', amount: '' });
                          setIsEditModeSalary(false);
                          setShowSalaryModal(true);
                        }}
                        className="btn-custom"
                      >
                        <FaPlus /> {t('FEE_VARIATIONS.ADD_NEW_SALARY')}
                      </Button>
                    </Card.Footer>
                  </Tab>
                  <Tab eventKey="fixedFees" title={t('FEE_VARIATIONS.FIXED_FEES')}>
                    <FixedFeesTable fees={selectedVariation.FixedFees} setVariation={setSelectedVariation} variation={selectedVariation} allowEdit={false} />
                  </Tab>
                  <Tab eventKey="ToolFees" title={t('FEE_VARIATIONS.TOOLS_FEES')}>
                    <ToolsFeesTable fees={selectedVariation.ToolFees} setVariation={setSelectedVariation} variation={selectedVariation} allowEdit={false} />
                  </Tab>
                </Tabs>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>

      {/* Add Fee Variation Modal */}
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton className="modal-header">
          <Modal.Title>{t('FEE_VARIATIONS.ADD_FEE_VARIATION')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => {
            e.preventDefault();
            handleAddVariation();
          }}>            <Form.Group controlId="variationName" className="form-group">
              <Form.Label>{t('FEE_VARIATIONS.NAME')}</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={newVariation.name}
                onChange={handleChange}
                required
                isInvalid={!!error}
              />
              <Form.Control.Feedback type="invalid">
                {error}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAddModal(false)}>{t('FEE_VARIATIONS.CLOSE')}</Button>
          <Button variant="primary" onClick={handleAddVariation}>{t('FEE_VARIATIONS.ADD_FEE_VARIATION')}</Button>
        </Modal.Footer>
      </Modal>

      {/* Add/Edit Tools Fee Modal */}
      <Modal show={showToolsModal} onHide={() => setShowToolsModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{isEditModeTools ? t('FEE_VARIATIONS.EDIT_TOOLS_FEE') : t('FEE_VARIATIONS.ADD_TOOLS_FEE')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => {
            e.preventDefault();
            handleToolsModalSave();
          }}>
            <Form.Group controlId="toolName">
              <Form.Label>{t('FEE_VARIATIONS.NAME')}</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={selectedTool.name}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="toolAmount">
              <Form.Label>{t('FEE_VARIATIONS.AMOUNT')}</Form.Label>
              <Form.Control
                type="number"
                name="amount"
                value={selectedTool.amount}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="toolFrequency">
              <Form.Label>{t('FEE_VARIATIONS.FREQUENCY')}</Form.Label>
              <Form.Control
                as="select"
                name="frequency"
                value={selectedTool.frequency}
                onChange={handleChange}
              >
                <option value="monthly">{t('FEE_VARIATIONS.MONTHLY')}</option>
                <option value="quarterly">{t('FEE_VARIATIONS.QUARTERLY')}</option>
                <option value="annually">{t('FEE_VARIATIONS.ANNUALLY')}</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowToolsModal(false)}>{t('FEE_VARIATIONS.CLOSE')}</Button>
          <Button variant="primary" onClick={handleToolsModalSave}>{isEditModeTools ? t('FEE_VARIATIONS.SAVE_CHANGES') : t('FEE_VARIATIONS.ADD_TOOLS_FEE')}</Button>
        </Modal.Footer>
      </Modal>

      {/* Add/Edit Employee Salary Modal */}
      <Modal show={showSalaryModal} onHide={() => setShowSalaryModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{isEditModeSalary ? t('FEE_VARIATIONS.EDIT_EMPLOYEE_SALARY') : t('FEE_VARIATIONS.ADD_EMPLOYEE_SALARY')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => {
            e.preventDefault();
            handleSalaryModalSave();
          }}>            <Form.Group controlId="employeeName">
              <Form.Label>{t('FEE_VARIATIONS.NAME')}</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={selectedSalary.name}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="employeeRole">
              <Form.Label>{t('FEE_VARIATIONS.ROLE')}</Form.Label>
              <Form.Control
                type="text"
                name="role"
                value={selectedSalary.role}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="employeeAmount">
              <Form.Label>{t('FEE_VARIATIONS.SALARY')}</Form.Label>
              <Form.Control
                type="number"
                name="amount"
                value={selectedSalary.amount}
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowSalaryModal(false)}>{t('FEE_VARIATIONS.CLOSE')}</Button>
          <Button variant="primary" onClick={handleSalaryModalSave}>{isEditModeSalary ? t('FEE_VARIATIONS.SAVE_CHANGES') : t('FEE_VARIATIONS.ADD_EMPLOYEE_SALARY')}</Button>
        </Modal.Footer>
      </Modal>

      {/* Add/Edit Fixed Fee Modal */}
      <Modal show={showFixedFeeModal} onHide={() => setShowFixedFeeModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{isEditModeFixedFee ? t('FEE_VARIATIONS.EDIT_FIXED_FEE') : t('FEE_VARIATIONS.ADD_FIXED_FEE')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => {
            e.preventDefault();
            handleFixedFeeModalSave();
          }}>               <Form.Group controlId="fixedFeeName">
              <Form.Label>{t('FEE_VARIATIONS.NAME')}</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={selectedFixedFee.name}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="fixedFeeAmount">
              <Form.Label>{t('FEE_VARIATIONS.AMOUNT')}</Form.Label>
              <Form.Control
                type="number"
                name="amount"
                value={selectedFixedFee.amount}
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowFixedFeeModal(false)}>{t('FEE_VARIATIONS.CLOSE')}</Button>
          <Button variant="primary" onClick={handleFixedFeeModalSave}>{isEditModeFixedFee ? t('FEE_VARIATIONS.SAVE_CHANGES') : t('FEE_VARIATIONS.ADD_FIXED_FEE')}</Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton className="modal-header">
          <Modal.Title>{t('FEE_VARIATIONS.CONFIRM_DELETE')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('FEE_VARIATIONS.DELETE_CONFIRMATION', { name: selectedVariation?.name })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>{t('FEE_VARIATIONS.CANCEL')}</Button>
          <Button variant="danger" onClick={handleDeleteVariation}>{t('FEE_VARIATIONS.DELETE')}</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default FeeVariationList;