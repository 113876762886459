import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import './styles.css'
import './style.css'
import './variable.css'
import { UserProvider } from './contexts/UserContext';
import { SubscriptionProvider } from './contexts/SubscriptionContext';

import Login from './pages/Login/Login';
import Signup from './pages/Signup/Signup';
import PrivacyPolicyAndTermsOfUse from './pages/Policies/PrivacyPolicyAndTermsOfUse';

import EmailVerification from './components/auth/EmailVerfication';
import EmailVerificationConfirm from './components/auth/EmailVerficiationConfirm';
import ForgotPassword from './components/auth/ForgotPassword';
import ResetPassword from './components/auth/ResetPassword';

import DashboardLayout from './layouts/DashboardLayout/DashboardLayout';
import Dashboard from './pages/Dashboard/Dashboard';

import Fees from './pages/FeeVariationList/FeeVariationList';
import FeeVariationDetails from './components/FeeVariation/FeesVariationDetails';
import Simulations from './pages/SimulationList/Simulation';
import SimulationDetails from './components/Simulation/SimulationDetails';
import CallCenter from './pages/CallCenter/CallCenter';
import Plans from './pages/Plans/Plans';
import Profile from './pages/Profile/Profile';
import Comparison from './pages/Comparaison/Comparaison';
import PayManually from './components/Payment/PayManually';



import AdminRoute from './components/Routes/AdminRoute';
import AuthenticatedRoute from './components/Routes/AuthenticatedRoute';
import ProtectedRoute from './components/Routes/ProtectedRoute';

import AdminPanel from './layouts/AdminPanelLayout/AdminPanelLayout';

import AdminUsers from './pages/Admin/AdminUsers';
import AdminPayments from './pages/Admin/AdminPayments';
import AdminDahboard from './pages/Admin/AdminDashboard';
import CryptomusWebhookTester from './pages/Admin/CryptomusWebhookTester';
import Unauthorized from './components/auth/Unauthorized';

ReactGA.initialize('G-RGPCDPVDJC');


function App() {
  const location = useLocation();

  useEffect(() => {
    // Log each page view
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);

  return (
    <UserProvider>
      <SubscriptionProvider>

        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/verify-email" element={<EmailVerification />} />
          <Route path="/confirm-email/:token" element={<EmailVerificationConfirm />} />
          <Route path="/forget-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/privacy-policy-and-terms-of-use" element={<PrivacyPolicyAndTermsOfUse />} />

          {/* Dashboard routes require authentication */}
          <Route element={<AuthenticatedRoute />}>
            <Route path="/dashboard" element={<DashboardLayout />}>
              <Route path="home" element={<Dashboard />} />
              <Route path="plans" element={<Plans />} />
              <Route path="profile" element={<Profile />} />
              <Route path='pay-manually' element={<PayManually />} />
              {/* Protected Routes require subscription */}
              <Route element={<ProtectedRoute />}>
                <Route path="fees" element={<Fees />} />
                <Route path="fees/:id" element={<FeeVariationDetails />} />
                <Route path="simulations" element={<Simulations />} />
                <Route path="simulations/:id" element={<SimulationDetails />} />
                <Route path="call-center" element={<CallCenter />} />
                <Route path="comparaison" element={<Comparison />} />
              </Route>
            </Route>
          </Route>

          {/* Admin routes */}
          <Route element={<AdminRoute />}>
            <Route path="/admin" element={<AdminPanel />}>
            <Route path="dashboard" element={<AdminDahboard />} />
              <Route path="users" element={<AdminUsers />} />
              <Route path='payments' element={<AdminPayments />} />
              <Route path='testCrypto' element={<CryptomusWebhookTester />} />
            </Route>
          </Route>
          {/* Unauthorized access */}
          <Route path="unauthorized" element={<Unauthorized />} />
        </Routes>
      </SubscriptionProvider>
    </UserProvider>
  );
}

export default App;