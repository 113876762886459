import React, { useState, useContext, useEffect } from 'react';
import {
  Container, Button, Card,
  Row, Col, Modal, Alert, Badge
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from '../../utils/axios';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../../contexts/LanguageContext';
import { BsCheck } from 'react-icons/bs';
import './Plans.css';

const Plans = () => {
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);
  const navigate = useNavigate();

  const [coupon, setCoupon] = useState('');
  const [isCouponValid, setIsCouponValid] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  const [error, setError] = useState(null);
  const [currentPlan, setCurrentPlan] = useState(null);

  const initialMonthlyPlan = {
    name: t('PLANS.MONTHLY_PLAN'),
    originalPrice: 3.99,
    price: 3.99,
    displayPrice: '€3.99/month',
    id: 'prod_QbHNj1v38VS4UQ',
    type: 'monthly'
  };

  const initialYearlyPlan = {
    name: t('PLANS.YEARLY_PLAN'),
    originalPrice: 36,
    price: 36,
    displayPrice: '€36/year (Save 25%)',
    id: 'prod_QbHNE3IYXGMxyW',
    type: 'yearly'
  };

  // New 3-months and 6-months plans
  const initialThreeMonthsPlan = {
    name: t('PLANS.THREE_MONTHS_PLAN'),
    originalPrice: 10.99,
    price: 11.97,
    displayPrice: '€10.99/3 months',
    id: 'prod_3MonthsId',  // Replace with actual product ID
    type: '3_months'
  };

  const initialSixMonthsPlan = {
    name: t('PLANS.SIX_MONTHS_PLAN'),
    originalPrice: 19.99,
    price: 19.99,
    displayPrice: '€19.99/6 months',
    id: 'prod_6MonthsId',  // Replace with actual product ID
    type: '6_months'
  };

  const [monthlyPlan, setMonthlyPlan] = useState(initialMonthlyPlan);
  const [yearlyPlan, setYearlyPlan] = useState(initialYearlyPlan);
  const [threeMonthsPlan, setThreeMonthsPlan] = useState(initialThreeMonthsPlan);
  const [sixMonthsPlan, setSixMonthsPlan] = useState(initialSixMonthsPlan);

  useEffect(() => {
    const fetchCurrentPlan = async () => {
      try {
        const response = await axios.get(`/payments/current-payment`);
        setCurrentPlan(response.data);
      } catch (error) {
        console.error("Failed to fetch current plan", error);
        setError("Error fetching current plan details");
      }
    };
    fetchCurrentPlan();
  }, []);

  const handleSubscribe = async (plan, paymentMethod) => {
    try {
      setError(null);
      const planId = plan.id;
      if (paymentMethod === 'stripe') {
        const { data } = await axios.post('/subscribe', { planId, plan, coupon }, { withCredentials: true });
        window.location.href = data.checkoutUrl;
      } else if (paymentMethod === 'chargily') {
        const { data } = await axios.post('/subscribe-chargily', { plan, coupon, language }, { withCredentials: true });
        window.location.href = data.checkoutUrl;

      } else if (paymentMethod === 'cryptomus') {
        // Create a crypto invoice
        const { data } = await axios.post('/cryptomus-invoice/create-invoice', {
          plan,
          url_callback: `${process.env.BACKEND_URL}/api/cryptomus-webhook`,
          url_success: `${process.env.BACKEND_URL}/success`,
          url_return: `${process.env.BACKEND_URL}/return`
        });

        if (data.result && data.result.url) {
          window.location.href = data.result.url; // Redirect user to the payment page
        } else {
          throw new Error('Failed to initiate crypto payment. Please try again.');
        }
      }
    } catch (error) {
      setError(error.response?.data?.message || 'Error subscribing to plan');
    }
  };

  const handlePlanSelection = (plan) => {
    setSelectedPlan(plan);
    setShowPaymentOptions(true);
  };

  // const handleApplyCoupon = async () => {
  //   try {
  //     const { data } = await axios.post('/validate-coupon', { coupon }, { withCredentials: true });
  //     if (data.valid) {
  //       setIsCouponValid(true);
  //       const discount = data.discount;

  //       setMonthlyPlan({
  //         ...initialMonthlyPlan,
  //         price: initialMonthlyPlan.originalPrice * ((100 - discount) / 100)
  //       });

  //       setYearlyPlan({
  //         ...initialYearlyPlan,
  //         price: initialYearlyPlan.originalPrice * ((100 - discount) / 100)
  //       });

  //       setThreeMonthsPlan({
  //         ...initialThreeMonthsPlan,
  //         price: initialThreeMonthsPlan.originalPrice * ((100 - discount) / 100)
  //       });

  //       setSixMonthsPlan({
  //         ...initialSixMonthsPlan,
  //         price: initialSixMonthsPlan.originalPrice * ((100 - discount) / 100)
  //       });

  //     } else {
  //       setIsCouponValid(false);
  //       setMonthlyPlan(initialMonthlyPlan);
  //       setYearlyPlan(initialYearlyPlan);
  //       setThreeMonthsPlan(initialThreeMonthsPlan);
  //       setSixMonthsPlan(initialSixMonthsPlan);
  //       setError(t('PLANS.INVALID_COUPON'));
  //     }
  //   } catch (error) {
  //     setIsCouponValid(false);
  //     setMonthlyPlan(initialMonthlyPlan);
  //     setYearlyPlan(initialYearlyPlan);
  //     setThreeMonthsPlan(initialThreeMonthsPlan);
  //     setSixMonthsPlan(initialSixMonthsPlan);
  //     setError(t('PLANS.INVALID_COUPON'));
  //   }
  // };

  const handleChangeCoupon = (e) => {
    setCoupon(e.target.value);
    setIsCouponValid(null);
    setError(null);
    setMonthlyPlan(initialMonthlyPlan);
    setYearlyPlan(initialYearlyPlan);
    setThreeMonthsPlan(initialThreeMonthsPlan);
    setSixMonthsPlan(initialSixMonthsPlan);
  };

  return (
    <Container className="plans-container">
      <h1 className="text-center mb-4">{t('PLANS.CHOOSE_YOUR_PLAN')}</h1>

      <Row className="cards-container justify-content-start">
        <Col xs={12} sm={6} md={6} lg={3} className="mb-4">
          <Card className="plan-card w-100 plan-card-0">
            <Card.Body className="d-flex flex-column">
              <Card.Title className="text-center plan-title mb-0">
                {t('PLANS.MONTHLY_PLAN')}
              </Card.Title>
              <Card.Text className="text-center">
                {isCouponValid ? (
                  <>
                    <span className="original-price" style={{ textDecoration: 'line-through', color: 'black' }}>
                      ${monthlyPlan.originalPrice.toFixed(2)}/ {t('PLANS.MONTH')}
                    </span>
                    <br />
                    <span className="discounted-price">
                      ${monthlyPlan.price.toFixed(2)}/ {t('PLANS.MONTH')}
                    </span>
                  </>
                ) : (
                  <span className="plan-price">
                    ${monthlyPlan.price.toFixed(2)}/ {t('PLANS.MONTH')}
                  </span>
                )}
              </Card.Text>

              <div className="plan-details">
                <p><BsCheck className='check-icon-yearly' /> {t('PLANS.PRICING_SIMULATOR')}</p>
                <p><BsCheck className='check-icon-yearly' /> {t('PLANS.SCALING_HELPER')}</p>
                <p><BsCheck className='check-icon-yearly' /> {t('PLANS.HIRING_HELPER')}</p>
              </div>

              <Button
                variant="primary"
                className="mt-auto align-self-center plan-btn"
                onClick={() => handlePlanSelection(monthlyPlan)}
              >
                {t('PLANS.BUY')}
              </Button>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} sm={6} md={6} lg={3} className="mb-4">
          <Card className="plan-card w-100 plan-card">
            <Card.Body className="d-flex flex-column">
           
              <Card.Title className="text-center plan-title mb-0">
                {t('PLANS.THREE_MONTHS_PLAN')}
              </Card.Title>
              <Card.Text className="text-center">
                {isCouponValid ? (
                  <>
                    <span className="original-price" style={{ textDecoration: 'line-through', color: 'black' }}>
                      ${threeMonthsPlan.originalPrice.toFixed(2)}/  {t('PLANS.THREE_MONTHS')}
                    </span>
                    <br />
                    <span className="discounted-price">
                      ${threeMonthsPlan.price.toFixed(2)}/  {t('PLANS.THREE_MONTHS')}
                    </span>
                  </>
                ) : (
                  <span className="plan-price">
                    ${threeMonthsPlan.price.toFixed(2)}/  {t('PLANS.THREE_MONTHS')}
                  </span>
                )}
              </Card.Text>

              <div className="plan-details">
                <p><BsCheck className='check-icon-yearly' /> {t('PLANS.PRICING_SIMULATOR')}</p>
                <p><BsCheck className='check-icon-yearly' /> {t('PLANS.SCALING_HELPER')}</p>
                <p><BsCheck className='check-icon-yearly' /> {t('PLANS.HIRING_HELPER')}</p>
              </div>

              <Button
                variant="primary"
                className="mt-auto align-self-center plan-btn"
                onClick={() => handlePlanSelection(threeMonthsPlan)}
              >
                {t('PLANS.BUY')}
              </Button>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} sm={6} md={6} lg={3} className="mb-4">
          <Card className="plan-card w-100 plan-card-1">
          <Badge pill  className="align-center popular">
                {t('PLANS.POPULAR')}
              </Badge>
            <Card.Body className="d-flex flex-column">
              <Card.Title className="text-center plan-title mb-0">
                {t('PLANS.SIX_MONTHS_PLAN')}
              </Card.Title>
              <Card.Text className="text-center">
                {isCouponValid ? (
                  <>
                    <span className="original-price" style={{ textDecoration: 'line-through', color: 'black' }}>
                      ${sixMonthsPlan.originalPrice.toFixed(2)}/  {t('PLANS.SIX_MONTHS')}
                    </span>
                    <br />
                    <span className="discounted-price">
                      ${sixMonthsPlan.price.toFixed(2)}/   {t('PLANS.SIX_MONTHS')}
                    </span>
                  </>
                ) : (
                  <span className="plan-price">
                    ${sixMonthsPlan.price.toFixed(2)}/   {t('PLANS.SIX_MONTHS')}
                  </span>
                )}
                 <Badge pill className="plan-savings">
                  {t('PLANS.16_OFF')}
                </Badge>
              </Card.Text>
                
              <div className="plan-details">
                <p><BsCheck className='check-icon-yearly' /> {t('PLANS.PRICING_SIMULATOR')}</p>
                <p><BsCheck className='check-icon-yearly' /> {t('PLANS.SCALING_HELPER')}</p>
                <p><BsCheck className='check-icon-yearly' /> {t('PLANS.HIRING_HELPER')}</p>
              </div>

              <Button
                variant="primary"
                className="mt-auto align-self-center plan-btn"
                onClick={() => handlePlanSelection(sixMonthsPlan)}
              >
                {t('PLANS.BUY')}
              </Button>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} sm={6} md={6} lg={3} className="mb-4">
          <Card className="plan-card w-100 plan-card">
            <Card.Body className="d-flex flex-column">
              <Card.Title className="text-center plan-title mb-0">
                {t('PLANS.YEARLY_PLAN')}
              </Card.Title>
              <Card.Text className="text-center">
                {isCouponValid ? (
                  <>
                    <span className="original-price" style={{ textDecoration: 'line-through', color: 'black' }}>
                      ${yearlyPlan.originalPrice.toFixed(2)}/ {t('PLANS.YEAR')}
                    </span>
                    <br />
                    <span className="discounted-price">
                      ${yearlyPlan.price.toFixed(2)}/ {t('PLANS.YEAR')}
                    </span>
                  </>
                ) : (
                  <span className="plan-price">
                    ${yearlyPlan.price.toFixed(2)}/ {t('PLANS.YEAR')}
                  </span>
                )}
                <Badge pill className="plan-savings">
                  {t('PLANS.20_OFF')}
                </Badge>
              </Card.Text>

              <div className="plan-details">
                <p><BsCheck className='check-icon-yearly' /> {t('PLANS.PRICING_SIMULATOR')}</p>
                <p><BsCheck className='check-icon-yearly' /> {t('PLANS.SCALING_HELPER')}</p>
                <p><BsCheck className='check-icon-yearly' /> {t('PLANS.HIRING_HELPER')}</p>
              </div>

              <Button
                variant="primary"
                className="mt-auto align-self-center plan-btn"
                onClick={() => handlePlanSelection(yearlyPlan)}
              >
                {t('PLANS.BUY')}
              </Button>
            </Card.Body>
          </Card>
        </Col>

      </Row>


      <Modal show={showPaymentOptions} onHide={() => setShowPaymentOptions(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('PLANS.SELECT_PAYMENT_METHOD')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button
            variant="success"
            className="w-100 mb-2"
            onClick={() => handleSubscribe(selectedPlan, 'chargily')}
          >
            {t('PLANS.PAY_WITH_DAHABIA_CIB')}
          </Button>
          {selectedPlan && selectedPlan.type === 'monthly' && (
            <Button
              variant="warning"
              className="w-100 mb-2"
              onClick={() => navigate('/dashboard/pay-manually')}
            >
              {t('PLANS.PAY_MANUALLY')}
            </Button>
          )}
          {/* <Button
            variant="warning"
            className="w-100"
            onClick={() => navigate('/dashboard/pay-manually')}
          >
            {t('PLANS.PAY_MANUALLY')}
          </Button> */}
          <Button
            variant="info"
            className="w-100 mb-2"
            onClick={() => handleSubscribe(selectedPlan, 'cryptomus')}
          >
            {t('PLANS.PAY_WITH_CRYPTO')}
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowPaymentOptions(false)}>{t('PLANS.CANCEL')}</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Plans;