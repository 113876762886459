import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Toast, Button, Row, Col } from 'react-bootstrap';
import axios from '../../utils/axios';
import { useTranslation } from 'react-i18next';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { debounce } from 'lodash';
import SimulationForm from './SimulationForm';
import SimulationPreview from './SimulationPreview';
import { formatNumberWithCurrency } from '../../utils/formatNumberWithCurrency';

const SimulationDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [simulation, setSimulation] = useState({
    sumOfToolsFees: 0,
    sumOfEmployeeSalaries: 0,
    sumOfFixedFees: 0,
  });
  const [errors, setErrors] = useState({});
  const [feeVariations, setFeeVariations] = useState([]);
  const [selectedFeeVariation, setSelectedFeeVariation] = useState(null);
  const [employees, setEmployees] = useState({});
  const [selectedEmployees, setSelectedEmployees] = useState({});
  const [initialSimulation, setInitialSimulation] = useState({});
  const [divisor, setDivisor] = useState(1);
  const [feeLoading, setFeeLoading] = useState(false); // New state for loading
  const [feeSuccess, setFeeSuccess] = useState(false); // New state for success
  const [isLoading, setIsLoading] = useState(true);
  const [showSaveToast, setShowSaveToast] = useState(false);
  const [showCancelToast, setShowCancelToast] = useState(false);
  
  const toggleSaveToast = () => setShowSaveToast(!showSaveToast);
  const toggleCancelToast = () => setShowCancelToast(!showCancelToast);

  const saveSimulation = useCallback(debounce(async (updatedSimulation) => {
    try {
      // const recalculatedSimulation = calculateFields(updatedSimulation, 'from savesimulation');
      console.log('updatedSimulation', updatedSimulation);
      const response = await axios.put(`/simulations/${id}`, updatedSimulation);
      console.log('Auto-saved simulation', response.data);
    } catch (error) {
      console.error('Error updating simulation', error);
    }
  }, 500), [id]);

  useEffect(() => {
    if (Object.keys(simulation).length > 0) {
      saveSimulation(simulation);
    }
    return () => saveSimulation.cancel();
  }, [simulation, saveSimulation]);

  const fetchSimulation = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/simulations/${id}`);
      const simulationData = response.data;
      // Normalize selected_employees to always be an array
    simulationData.selected_employees = Array.isArray(simulationData.selected_employees)
    ? simulationData.selected_employees
    : [];
      setSimulation(simulationData);
      setInitialSimulation(simulationData);
      setSelectedFeeVariation(simulationData.fee_variation_id);

      const initialSelectedEmployees = (simulationData?.selected_employees || []).reduce((acc, item) => {
        acc[item.role] = item.employeeId;
        return acc;
      }, {});
      setSelectedEmployees(initialSelectedEmployees);
    } catch (error) {
      console.error('Error fetching simulation data', error);
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  const fetchFeeVariations = useCallback(async () => {
    try {
      const response = await axios.get('/fee-variations', { withCredentials: true });
      setFeeVariations(response.data);
    } catch (error) {
      console.error('Error fetching fee variations data', error);
    }
  }, []);

  const fetchDivisor = useCallback(async (feeVariationId, deliveredOrdersDailyOverride = null) => {
    if (!feeVariationId || simulation.type !== 'product') {
      setDivisor(1);
      return 1;
    }
    try {
      const response = await axios.get(`/simulations/divisor/${feeVariationId}/${simulation.id}`, {
        params: { delivered_orders_daily: deliveredOrdersDailyOverride }, // Send current value
      });
      const divisorCount = response.data.divisor || 1;
      setDivisor(divisorCount);
      return divisorCount;
    } catch (error) {
      console.error('Error fetching divisor:', error);
      setDivisor(1);
      return 1;
    }
  }, [simulation.type, t]);

  useEffect(() => {
    fetchSimulation();
    fetchFeeVariations();
  }, [fetchSimulation, fetchFeeVariations]);

  const calculateCostOrOrders = (simulationData) => {
    const {
      calculate_based_on,
      daily_ads_spend = 0,
      cost = 1,
      orders = 1
    } = simulationData;

    const safeDailyAdsSpend = Number(daily_ads_spend) || 0;
    const safeCost = Number(cost) || 1;
    const safeOrders = Number(orders) || 1;

    let updatedSimulation = { ...simulationData };

    try {
      switch (calculate_based_on) {
        case 'Enter Cost Manually':
          updatedSimulation.orders = (safeDailyAdsSpend / safeCost).toFixed(2);
          break;
        case 'Enter Orders Number':
          updatedSimulation.cost = (safeDailyAdsSpend / safeOrders).toFixed(2);
          break;
        default:
          updatedSimulation.orders = safeOrders.toFixed(2);
          updatedSimulation.cost = safeCost.toFixed(2);
      }
      updatedSimulation.orders = isNaN(updatedSimulation.orders) ? '0.00' : updatedSimulation.orders;
      updatedSimulation.cost = isNaN(updatedSimulation.cost) ? '0.00' : updatedSimulation.cost;
      return updatedSimulation;
    } catch (error) {
      console.error('Error in cost/orders calculation:', error);
      return {
        ...simulationData,
        orders: '0.00',
        cost: '0.00'
      };
    }
  };

  const handleSelectFeeVariation = async (e) => {
    setFeeLoading(true);
    setFeeSuccess(false);

    const feeVariationId = e.target.value === '' ? null : parseInt(e.target.value, 10);
    setSelectedFeeVariation(feeVariationId);
    setSelectedEmployees({});

    const newDivisor = await fetchDivisor(feeVariationId, simulation.delivered_orders_daily);
    const resetSimulationState = {
      sumOfToolsFees: 0,
      sumOfEmployeeSalaries: 0,
      sumOfFixedFees: 0,
      selected_employees: [],
      fee_role_per_order: 0,
      other_fee_per_order: 0,
      fee_variation_id: feeVariationId,
    };

    let resetSimulation;
    const feeVariation = feeVariations.find((fv) => fv.id === feeVariationId);
    const sums = calculateSums(feeVariation, simulation.euro_price);
    const totals = calculateTotals(sums, simulation.delivered_orders_daily, newDivisor);
    if (feeVariationId === null) {
      resetSimulation = {
        ...simulation,
        ...resetSimulationState,
        ...sums,
        ...totals,
        fee_variation_id: null,
      };
    } else {
      const feeVariation = feeVariations.find((fv) => fv.id === feeVariationId);
      const sums = calculateSums(feeVariation, simulation.euro_price);
      const totals = calculateTotals(sums, simulation.delivered_orders_daily, newDivisor);
      resetSimulation = {
        ...simulation,
        ...resetSimulationState,
        ...sums,
        ...totals,
        fee_variation_id: feeVariationId,
      };
    }

    const updatedSimulationWithDivisor = calculateFields(
      resetSimulation,
      'from handleSelectFeeVariation'
    );
    setSimulation(updatedSimulationWithDivisor);

    const saveSuccess = await saveSimulation(updatedSimulationWithDivisor);
    setFeeLoading(false);

    if (saveSuccess) {
      setFeeSuccess(true);
      setTimeout(() => setFeeSuccess(false), 3000); // Hide success after 3 seconds
    }
  };

  const handleEmployeeChange = (roleId, employeeId) => {
    setSelectedEmployees(prev => {
      const updatedEmployees = { ...prev, [roleId]: employeeId };
      const updatedSimulation = {
        ...simulation,
        selected_employees: Object.entries(updatedEmployees).map(([role, id]) => ({ role, employeeId: id })),
      };
      const recalculatedSimulation = calculateFields(updatedSimulation, 'from handleEmployeeChange');
      setSimulation(recalculatedSimulation);
      return updatedEmployees;
    });
  };

  const selectedFeeVariationData = useMemo(() => {
    return (
      feeVariations.find((fv) => fv.id === selectedFeeVariation) || {
        ToolFees: [],
        FixedFees: [],
        EmployeeSalaries: [],
      }
    );
  }, [selectedFeeVariation, feeVariations]);

  const filteredEmployeesByRole = useMemo(() => {
    if (!selectedFeeVariationData || !selectedFeeVariationData.EmployeeSalaries) return {};

    return selectedFeeVariationData.EmployeeSalaries.reduce((acc, salary) => {
      if (salary.variable_amount > 0) {
        if (!acc[salary.role]) {
          acc[salary.role] = [];
        }
        acc[salary.role].push(salary);
      }
      return acc;
    }, {});
  }, [selectedFeeVariationData]);

  useEffect(() => {
    if (selectedFeeVariationData && selectedFeeVariationData.EmployeeSalaries) {
      const employeesData = selectedFeeVariationData.EmployeeSalaries.reduce((acc, salary) => {
        if (salary.calculation_basis === 'variable' || salary.calculation_basis === 'both') {
          if (!acc[salary.role]) {
            acc[salary.role] = [];
          }
          acc[salary.role].push({
            id: salary.id,
            name: salary.name,
            variable_amount: salary.variable_amount,
            per_order_basis: salary.per_order_basis
          });
        }
        return acc;
      }, {});
      setEmployees(employeesData);
    }
  }, [selectedFeeVariation, selectedFeeVariationData]);


  
  useEffect(() => {
    const updateSimulationOnChange = async () => {
  
      // Step 1: Fetch the divisor if fee_variation_id exists
      const newDivisor = await fetchDivisor(simulation.fee_variation_id, simulation.delivered_orders_daily);
  
      // Step 2: Calculate sums based on the fee variation and euro_price
      const feeVariation = feeVariations.find(fv => fv.id === simulation.fee_variation_id);
      const sums = calculateSums(feeVariation, simulation.euro_price);
  
      // Step 3: Calculate totals using the new divisor and delivered_orders_daily
      const totals = calculateTotals(sums, simulation.delivered_orders_daily, newDivisor);
  
      // Step 4: Update simulation with new sums and totals, then recalculate fields
      const updatedSimulation = calculateFields({
        ...simulation,
        ...sums,
        ...totals,
        divisor: newDivisor, // Optionally store divisor in simulation if needed
      }, 'from useEffect (delivered_orders or fee_variation_id)');
  
      // Step 5: Set the updated simulation state
      setSimulation(updatedSimulation);
    };
  
    // Only run if simulation data is loaded and either delivered_orders_daily or fee_variation_id changes
    if (Object.keys(simulation).length > 0) {
      updateSimulationOnChange();
    }
  }, [
    simulation.delivered_orders_daily, // Trigger on delivered_orders change
    simulation.fee_variation_id,       // Trigger on fee_variation_id change
    simulation.euro_price,             // Include if sums depend on it
    feeVariations,                     // Include if feeVariations data is needed
    fetchDivisor,                      // Include as a dependency
  ]);
  
  // useEffect(() => {
  //   console.log('I am here, divisor -selectedFeeVariation:', divisor, selectedFeeVariation);
  //   if (selectedFeeVariation !== null && simulation.fee_variation_id !== selectedFeeVariation) {
  //     const feeVariation = feeVariations.find(fv => fv.id === selectedFeeVariation);
  //     if (feeVariation) {
  //       const sums = calculateSums(feeVariation, simulation.euro_price);
  //       const newDivisor = fetchDivisor(selectedFeeVariation);
  //       console.log('newDivisor:', newDivisor);
  //       const totals = calculateTotals(sums, simulation.delivered_orders_daily, newDivisor);
  //       const updatedSimulation = calculateFields({
  //         ...simulation,
  //         fee_variation_id: selectedFeeVariation,
  //         ...sums,
  //         ...totals,
  //       }, 'from useEffect (selectedFeeVariation)');
  //       setSimulation(updatedSimulation);
  //     }
  //   }
  // }, [feeVariations, simulation.euro_price, simulation.delivered_orders_daily, simulation.fee_variation_id, divisor]);

  const calculateFields = (data, functionfrom = 'idk') => {
    data.monthly_ads_spend = data.daily_ads_spend * 30;
    data.fee_role_per_order = 0;
    let totalFeeRolePerOrder = 0;

    (data.selected_employees || []).forEach(({ role, employeeId }) => {
      if (employees[role]) {
        const selectedEmp = employees[role].find(emp => emp.id === parseInt(employeeId, 10));
        if (selectedEmp) {
          let feeRolePerOrder = 0;
          if (selectedEmp.per_order_basis === 'delivered') {
            feeRolePerOrder = selectedEmp.variable_amount;
          } else if (selectedEmp.per_order_basis === 'confirmed') {
            feeRolePerOrder = (selectedEmp.variable_amount * (data.confirmed_orders_daily || 0)) / (data.delivered_orders_daily || 1);
          }
          totalFeeRolePerOrder += feeRolePerOrder;
        }
      }
    });

    data.fee_role_per_order = totalFeeRolePerOrder;
    data.confirmation_cost_per_order = ((data.euro_price * data.cost * 100) / (data.confirmation_percentage || 1));
    data.delivery_cost_per_order = (data.confirmation_cost_per_order * 100) / (data.delivery_percentage || 1);
    data.confirmed_orders_daily = ((data.euro_price * data.daily_ads_spend) / (data.confirmation_cost_per_order || 1)) || 0;
    data.confirmed_orders_monthly = data.confirmed_orders_daily * 30;
    data.delivered_orders_daily = (data.euro_price * data.daily_ads_spend) / (data.delivery_cost_per_order || 1);
    data.delivered_orders_monthly = data.delivered_orders_daily * 30;

    data.cod_fee_per_order = (data.cod_fee / 100) * data.sale_price;
    data.cod_fee_daily = data.delivered_orders_daily * data.cod_fee_per_order;
    data.cod_fee_monthly = data.cod_fee_daily * 30;

    data.return_fee_daily = (data.confirmed_orders_daily - data.delivered_orders_daily) * (data.return_fee || 0);
    data.return_fee_per_order = data.return_fee_daily / (data.delivered_orders_daily || 1);
    data.return_fee_monthly = data.return_fee_daily * 30;

    data.net_profit = data.sale_price - (data.product_price + data.cod_fee_per_order + data.return_fee_per_order + (data.other_fee_per_order || 0) + Number(data.delivery_price || 0) + Number(data.packaging_fee || 0) + data.delivery_cost_per_order + data.fee_role_per_order);
    data.net_profit_daily = data.net_profit * data.delivered_orders_daily;
    data.net_profit_monthly = data.net_profit_daily * 30;

    const costComponentsSum = (Number(data.product_price) + Number(data.delivery_cost_per_order) + Number(data.packaging_fee || 0) + Number(data.other_fee_per_order || 0));
    data.roi_per_order = costComponentsSum !== 0 ? (Number(data.net_profit) / costComponentsSum) * 100 : 0;
    data.roas_per_order = data.sale_price / (data.cost * data.euro_price) || 0;
    return data;
  };

  const calculateSums = (feeVariation, euroPrice) => {
    if (!feeVariation) return {
      sumOfFixedFees: 0,
      sumOfEmployeeSalaries: 0,
      tools_fee_monthly: 0
    };

    const adjustSum = (sum, frequency, amount) => {
      switch (frequency) {
        case 'Daily': return sum + (amount * 30);
        case 'Monthly': return sum + amount;
        case 'Quarterly': return sum + (amount / 3);
        case 'Yearly': return sum + (amount / 12);
        default: return sum;
      }
    };

    let sumOfToolsFeesDZD = feeVariation.ToolFees.reduce((sum, fee) => adjustSum(sum, fee.frequency, fee.amount_in_DZD), 0);
    let sumOfFixedFees = feeVariation.FixedFees.reduce((sum, fee) => adjustSum(sum, fee.frequency, fee.amount), 0);
    let sumOfEmployeeSalaries = feeVariation.EmployeeSalaries.reduce((sum, salary) => sum + salary.fixed_amount, 0);

    return {
      sumOfFixedFees,
      sumOfEmployeeSalaries,
      sumOfToolsFeesDZD
    };
  };

  const calculateTotals = (sums, deliveredOrdersDaily, divisorValue) => {
    const { sumOfEmployeeSalaries, sumOfFixedFees, sumOfToolsFeesDZD } = sums;

    let fixed_fee_monthly = sumOfFixedFees;
    let employee_salary_monthly = sumOfEmployeeSalaries;
    let tools_fee_monthly = sumOfToolsFeesDZD;

    let tools_fee_daily = tools_fee_monthly / 30;
    let fixed_fee_daily = fixed_fee_monthly / 30;
    let employee_salary_daily = employee_salary_monthly / 30;
    const total_fee_daily = fixed_fee_daily + tools_fee_daily + employee_salary_daily;
    const other_fee_per_order = deliveredOrdersDaily
      ? (total_fee_daily * (simulation.type === 'product' ? divisorValue : 1)) / deliveredOrdersDaily
      : 0;

    if (simulation.type === 'product' && divisorValue ) {
      tools_fee_daily *= divisorValue;
      fixed_fee_daily *= divisorValue;
      employee_salary_daily *= divisorValue;
      tools_fee_monthly *= divisorValue;
      fixed_fee_monthly *= divisorValue;
      employee_salary_monthly *= divisorValue;
    }

    return {
      tools_fee_daily,
      employee_salary_daily,
      fixed_fee_daily,
      tools_fee_monthly,
      employee_salary_monthly,
      fixed_fee_monthly,
      total_fee_daily,
      other_fee_per_order
    };
  };

  const handleUpdateSimulation = async () => {
    try {
      const updatedSimulation = {
        ...simulation,
        selected_employees: Object.entries(selectedEmployees).map(([role, employeeId]) => ({
          role,
          employeeId,
        })),
      };
      const recalculatedSimulation = calculateFields(updatedSimulation, 'from handleUpdateSimulation');
      const response = await axios.put(`/simulations/${id}`, recalculatedSimulation);
      setSimulation(response.data);
      setInitialSimulation(response.data);
      toggleSaveToast();
    } catch (error) {
      console.error('Error updating simulation', error);
      toast.error('Error updating simulation. Please try again.');
    }
  };

  const handleCancelUpdate = () => {
    setSimulation(initialSimulation);
    toggleCancelToast();
  };
  const validateForm = () => {
  const newErrors = {};

  if (!simulation.name?.trim()) {
    newErrors.name = t('SIMULATION.NAME_REQUIRED') || 'Name is required';
  }
  if (!simulation.cost || parseFloat(simulation.cost) <= 0) {
    newErrors.cost = t('SIMULATION.COST_MUST_BE_POSITIVE') || 'Cost must be greater than 0';
  }
  if (!simulation.euro_price || parseFloat(simulation.euro_price) <= 0) {
    newErrors.euro_price = t('SIMULATION.EURO_PRICE_MUST_BE_POSITIVE') || 'Euro price must be greater than 0';
  }
  if (!simulation.daily_ads_spend || parseFloat(simulation.daily_ads_spend) <= 0) {
    newErrors.daily_ads_spend = t('SIMULATION.DAILY_SPEND_MUST_BE_POSITIVE') || 'Daily ads spend must be greater than 0';
  }
  if (!simulation.confirmation_percentage || simulation.confirmation_percentage <= 0) {
    newErrors.confirmation_percentage = t('SIMULATION.CONFIRMATION_PERCENTAGE_MUST_BE_POSITIVE') || 'Confirmation percentage must be greater than 0';
  }
  if (!simulation.delivery_percentage || simulation.delivery_percentage <= 0) {
    newErrors.delivery_percentage = t('SIMULATION.DELIVERY_PERCENTAGE_MUST_BE_POSITIVE') || 'Delivery percentage must be greater than 0';
  }

  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};
  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue;
    if (value === '') {
      newValue = null;
    } else if (['packaging_fee', 'product_price', 'sale_price', 'euro_price', 'daily_ads_spend', 'cost', 'orders', 'confirmation_percentage', 'delivery_percentage', 'return_fee', 'cod_fee'].includes(name)) {
      newValue = parseFloat(value) || 0;
    } else {
      newValue = value;
    }

    let updatedSimulation = { ...simulation, [name]: newValue };

    if (['calculate_based_on', 'cost', 'orders', 'daily_ads_spend'].includes(name)) {
      updatedSimulation = calculateCostOrOrders(updatedSimulation);
    }

    updatedSimulation = calculateFields(updatedSimulation, 'from handleChange');
    setSimulation(updatedSimulation);
  };

  const handleSliderChange = (value, name) => {
    const parsedValue = parseFloat(value) || 0; // Ensure numeric value

    let updatedSimulation = { ...simulation, [name]: parsedValue };

    if (['cost', 'orders', 'daily_ads_spend'].includes(name)) {
      updatedSimulation = calculateCostOrOrders(updatedSimulation);
    }

    const recalculatedSimulation = calculateFields(updatedSimulation, 'from handleSliderChange');
    setSimulation(recalculatedSimulation);
  };

  if (!simulation) {
    return <div>{t('SIMULATION.SIMULATION_NOT_FOUND')}</div>;
  }

  return (
    <Container className="simulation-details-container">
      <ToastContainer position="top-right" />
      <Row className="align-items-center mb-2">
        <Col lg={9} xs={12}>
          <div className="section-title">{t('SIMULATION.SIMULATION_DETAILS')}</div>
          <div className="section-description">{t('SIMULATION.SIMULATION_DETAILS_DESCRIPTION')}</div>
        </Col>
        <Col lg={3} xs={12} className="ml-auto">
          <Button variant="secondary" onClick={handleCancelUpdate} className="mt-3 mr-3 ml-3">
            {t('SIMULATION.CANCEL')}
          </Button>
          <Button variant="primary" onClick={handleUpdateSimulation} className="mt-3">
            {t('SIMULATION.UPDATE')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={7}>
          <SimulationForm
            simulation={simulation}
            handleChange={handleChange}
            handleSliderChange={handleSliderChange}
            selectedFeeVariation={selectedFeeVariationData}
            setSelectedFeeVariation={setSelectedFeeVariation}
            feeVariations={feeVariations}
            handleSelectFeeVariation={handleSelectFeeVariation}
            feeLoading={feeLoading}  // Pass new props
            feeSuccess={feeSuccess}  // Pass new props
          />
          <Row className="justify-content-end">
            <Col xs="auto">
              <Button variant="secondary" onClick={handleCancelUpdate} className="mt-3">
                {t('SIMULATION.CANCEL')}
              </Button>
            </Col>
            <Col xs="auto">
              <Button variant="primary" onClick={handleUpdateSimulation} className="mt-3">
                {t('SIMULATION.UPDATE')}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xs={12} lg={5}>
          <SimulationPreview
            simulation={simulation}
            feeVariations={feeVariations}
            filteredEmployeesByRole={filteredEmployeesByRole}
            handleEmployeeChange={handleEmployeeChange}
          />
        </Col>
      </Row>
      <Toast
        show={showSaveToast}
        onClose={toggleSaveToast}
        delay={3000}
        autohide
        style={{ position: 'fixed', top: 20, right: 20 }}
      >
        <Toast.Header>
          <strong className="mr-auto">{t('SIMULATION.SUCCESS')}</strong>
        </Toast.Header>
        <Toast.Body>{t('SIMULATION.CHANGES_SAVED')}</Toast.Body>
      </Toast>
      <Toast
        show={showCancelToast}
        onClose={toggleCancelToast}
        delay={3000}
        autohide
        style={{ position: 'fixed', top: 20, right: 20 }}
      >
        <Toast.Header>
          <strong className="mr-auto">{t('SIMULATION.CANCELLED')}</strong>
        </Toast.Header>
        <Toast.Body>{t('SIMULATION.CHANGES_CANCELLED')}</Toast.Body>
      </Toast>
    </Container>
  );
};

export default SimulationDetails;