import React, { useState, useEffect } from 'react';
import {
  Table, Button, Form, FormControl, Modal, InputGroup, Container,
  Col, Row, Spinner, Pagination, Badge, Card, OverlayTrigger,
  Tooltip, Dropdown, Toast, ToastContainer
} from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import axios from '../../utils/axios';
import Avatar1 from '../../assets/avatar1.jpg';
import Avatar2 from '../../assets/avatar2.jpg';
import { useNavigate } from 'react-router-dom';
import {
  FaUserPlus, FaSearch, FaFileDownload, FaEllipsisV, FaUserShield,
  FaCheckCircle, FaBan, FaSync, FaCreditCard, FaWhatsapp, FaFilter,
  FaCalendarAlt
} from 'react-icons/fa';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [renewModalInfo, setRenewModalInfo] = useState({ show: false, userId: null, planType: null });
  const [paymentModalInfo, setPaymentModalInfo] = useState({ show: false, userId: null, userName: '' });
  const [newUser, setNewUser] = useState({ avatar: '', name: '', email: '', country: '', phoneNumber: '', password: '' });
  const [errors, setErrors] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filterStatus, setFilterStatus] = useState('all');
  const [filterEmailStatus, setFilterEmailStatus] = useState('all');
  const [filterRole, setFilterRole] = useState('all');
  const [filterPlan, setFilterPlan] = useState('all');
  const [filterCountry, setFilterCountry] = useState('all');
  const [filterSubscriptionRange, setFilterSubscriptionRange] = useState({ startDate: '', endDate: '' });
  const [filterCreatedRange, setFilterCreatedRange] = useState({ startDate: '', endDate: '' });
  const [sortField, setSortField] = useState('id');
  const [sortDirection, setSortDirection] = useState('asc');
  const [limit, setLimit] = useState(10);
  const [toast, setToast] = useState({ show: false, message: '', variant: 'success' });
  const navigate = useNavigate();

  useEffect(() => {
    fetchUsers();
  }, [page, searchQuery, filterStatus, filterEmailStatus, filterRole, filterPlan, filterCountry, 
      filterSubscriptionRange, filterCreatedRange, sortField, sortDirection, limit]);

  const fetchUsers = async (preservePosition = false) => {
    setLoading(true);
    try {
      const response = await axios.get('/user/all-users', {
        params: {
          search: searchQuery,
          page: preservePosition ? page : 1,
          limit,
          status: filterStatus !== 'all' ? filterStatus : undefined,
          emailStatus: filterEmailStatus !== 'all' ? filterEmailStatus : undefined,
          role: filterRole !== 'all' ? filterRole : undefined,
          plan: filterPlan !== 'all' ? filterPlan : undefined,
          country: filterCountry !== 'all' ? filterCountry : undefined,
          subscriptionStartDate: filterSubscriptionRange.startDate || undefined,
          subscriptionEndDate: filterSubscriptionRange.endDate || undefined,
          createdStartDate: filterCreatedRange.startDate || undefined,
          createdEndDate: filterCreatedRange.endDate || undefined,
          sortField,
          sortDirection
        }
      });
      setUsers(response.data.users || []);
      setTotalPages(Math.ceil(response.data.count / limit));
    } catch (error) {
      console.error("Error fetching users", error);
      setToast({ show: true, message: 'Error fetching users', variant: 'danger' });
    } finally {
      setLoading(false);
    }
  };

  const fetchAllUsersForExport = async () => {
    setExportLoading(true);
    try {
      const response = await axios.get('/user/all-users', {
        params: {
          limit: 10000,
          page: 1
        }
      });
      if (response.data && response.data.users && response.data.users.length > 0) {
        const allData = response.data.users.map(user => ({
          id: user.id,
          name: user.name,
          email: user.email,
          email_verified: user.email_verified ? 'Yes' : 'No',
          phone_number: user.phoneNumber || '',
          country: user.country || '',
          role: user.role || 'user',
          status: user.active ? 'Active' : 'Inactive',
          plan: user.plan || '',
          subscription_end_date: user.subscriptionEndDate ? new Date(user.subscriptionEndDate).toLocaleDateString() : 'N/A',
          created_at: user.createdAt ? new Date(user.createdAt).toLocaleDateString() : 'N/A'
        }));
        setAllUsers(allData);
      }
      document.getElementById('export-all-csv-link').click();
    } catch (error) {
      console.error("Error exporting all users", error);
      setToast({ show: true, message: 'Error exporting users', variant: 'danger' });
    } finally {
      setExportLoading(false);
    }
  };

  const handleAvatarChange = (avatar) => {
    setNewUser({ ...newUser, avatar });
  };

  const handleChange = (e) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  };

  const handleResetFilters = () => {
    setFilterStatus('all');
    setFilterEmailStatus('all');
    setFilterRole('all');
    setFilterPlan('all');
    setFilterCountry('all');
    setFilterSubscriptionRange({ startDate: '', endDate: '' });
    setFilterCreatedRange({ startDate: '', endDate: '' });
    setSearchQuery('');
    setPage(1);
  };

  const handleFilterChange = (status) => {
    setFilterStatus(status);
    setPage(1);
  };

  const handleEmailStatusFilterChange = (status) => {
    setFilterEmailStatus(status);
    setPage(1);
  };

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleConnectAsUser = async (userId) => {
    try {
      const response = await axios.post('/admin/admin-login-as-user', { userId });
      const { token, user } = response.data.result;

      localStorage.setItem('avatar', user.avatar);
      localStorage.setItem('name', (user.name || '').split(' ')[0]);
      navigate('/dashboard/simulations');
    } catch (error) {
      console.error("Error connecting as user", error);
      setToast({ show: true, message: 'Error connecting as user', variant: 'danger' });
    }
  };

  const handleLimitChange = (newLimit) => {
    setLimit(parseInt(newLimit));
    setPage(1);
  };

  const handleDisableUser = async (userId, currentIsActive) => {
    try {
      const newIsActive = !currentIsActive;
      await axios.put(`/user/${newIsActive ? 'enable' : 'disable'}/${userId}`);
      
      setUsers(prevUsers => prevUsers.map(user => 
        user.id === userId ? { ...user, active: newIsActive } : user
      ));
      
      setToast({ 
        show: true, 
        message: `User ${newIsActive ? 'enabled' : 'disabled'} successfully`,
        variant: 'success' 
      });
    } catch (error) {
      console.error("Error changing user status", error);
      setToast({ 
        show: true, 
        message: 'Failed to update user status', 
        variant: 'danger' 
      });
    }
  };

  const handleVerifyUser = async (userId) => {
    try {
      await axios.put(`/user/verify/${userId}`);
      setUsers(prevUsers => prevUsers.map(user => 
        user.id === userId ? { ...user, email_verified: true } : user
      ));
      setToast({ 
        show: true, 
        message: 'User verified successfully', 
        variant: 'success' 
      });
    } catch (error) {
      console.error("Error verifying user", error);
      setToast({ 
        show: true, 
        message: 'Failed to verify user', 
        variant: 'danger' 
      });
    }
  };

  const handleRenewPlan = async () => {
    try {
      const { userId, planType } = renewModalInfo;
      const response = await axios.post('/user/renew-plan', { userId, planType });
      
      setUsers(prevUsers => prevUsers.map(user => 
        user.id === userId ? { 
          ...user, 
          plan: planType,
          subscription_end: response.data.newEndDate
        } : user
      ));
      
      setRenewModalInfo({ show: false, userId: null, planType: null });
      setToast({ 
        show: true, 
        message: 'Plan renewed successfully', 
        variant: 'success' 
      });
    } catch (error) {
      console.error("Error renewing plan", error);
      setToast({ 
        show: true, 
        message: 'Failed to renew plan', 
        variant: 'danger' 
      });
    }
  };

  const handleGrantPayment = async () => {
    const { userId, userName } = paymentModalInfo;
    if (!userId) {
      setToast({ 
        show: true, 
        message: 'User ID is required', 
        variant: 'danger' 
      });
      return;
    }

    try {
      const response = await axios.post(`/user/grant-payment/${userId}`);
      
      setUsers(prevUsers => prevUsers.map(user => 
        user.id === userId ? { 
          ...user, 
          plan: 'monthly',
          subscription_end: response.data.newEndDate
        } : user
      ));

      setPaymentModalInfo({ show: false, userId: null, userName: '' });
      setToast({ 
        show: true, 
        message: `Payment granted to ${userName} successfully`,
        variant: 'success' 
      });
    } catch (error) {
      console.error("Error granting payment", error);
      setToast({ 
        show: true, 
        message: error.response?.data?.message || 'Failed to grant payment',
        variant: 'danger' 
      });
    }
  };

  const PaginationControls = () => {
    if (totalPages <= 1) return null;

    return (
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <span className="me-2">Show</span>
          <Form.Select
            size="sm"
            value={limit}
            onChange={(e) => handleLimitChange(e.target.value)}
            style={{ width: '80px' }}
            className="me-2"
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </Form.Select>
          <span>records</span>
        </div>

        <Pagination className="mb-0">
          <Pagination.First onClick={() => handlePageChange(1)} disabled={page === 1} />
          <Pagination.Prev onClick={() => handlePageChange(page - 1)} disabled={page === 1} />
          {page > 2 && <Pagination.Item onClick={() => handlePageChange(1)}>1</Pagination.Item>}
          {page > 3 && <Pagination.Ellipsis disabled />}
          {page > 1 && <Pagination.Item onClick={() => handlePageChange(page - 1)}>{page - 1}</Pagination.Item>}
          <Pagination.Item active>{page}</Pagination.Item>
          {page < totalPages && <Pagination.Item onClick={() => handlePageChange(page + 1)}>{page + 1}</Pagination.Item>}
          {page < totalPages - 2 && <Pagination.Ellipsis disabled />}
          {page < totalPages - 1 && <Pagination.Item onClick={() => handlePageChange(totalPages)}>{totalPages}</Pagination.Item>}
          <Pagination.Next onClick={() => handlePageChange(page + 1)} disabled={page === totalPages} />
          <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={page === totalPages} />
        </Pagination>
      </div>
    );
  };

  const formatPhoneForWhatsApp = (phone) => {
    if (!phone) return '';
    return phone.replace(/\D/g, '');
  };

  const openWhatsApp = (phone) => {
    const formattedPhone = formatPhoneForWhatsApp(phone);
    if (formattedPhone) {
      window.open(`https://wa.me/${formattedPhone}`, '_blank');
    }
  };

  const getSortIcon = (field) => {
    if (sortField !== field) return null;
    return sortDirection === 'asc' ? '↑' : '↓';
  };

  const renderUserStatusBadge = (user) => {
    return user.active ? <Badge bg="success">Active</Badge> : <Badge bg="danger">Inactive</Badge>;
  };

  const renderEmailVerificationBadge = (user) => {
    return user.email_verified ? 
      <Badge bg="success">Verified</Badge> : 
      <Badge bg="danger">Unverified</Badge>;
  };

  const formatDate = (dateString) => {
    if (!dateString) return '-';
    return new Date(dateString).toLocaleDateString();
  };

  return (
    <Container fluid>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          show={toast.show}
          onClose={() => setToast({ ...toast, show: false })}
          delay={3000}
          autohide
          bg={toast.variant}
        >
          <Toast.Header>
            <strong className="me-auto">
              {toast.variant === 'success' ? 'Success' : 'Error'}
            </strong>
          </Toast.Header>
          <Toast.Body className="text-white">{toast.message}</Toast.Body>
        </Toast>
      </ToastContainer>

      <Card className="mb-4 shadow-sm">
        <Card.Body>
          <Row className="align-items-center mb-3">
            <Col md={8}>
              <h2 className="mb-0">User Management</h2>
              <p className="text-muted">Manage your users with easy actions</p>
            </Col>
          </Row>

          <Row className="mb-3 g-3">
            <Col>
              <Card className="shadow-sm">
                <Card.Body>
                  <Row className="align-items-center mb-3">
                    <Col>
                      <h5 className="mb-0">Filters</h5>
                    </Col>
                    <Col className="text-end">
                      <Button
                        variant="link"
                        className="p-0 text-decoration-none"
                        onClick={handleResetFilters}
                      >
                        Clear all filters
                      </Button>
                    </Col>
                  </Row>

                  <Row className="g-3">
                    <Col md={4} lg={3}>
                      <InputGroup>
                        <InputGroup.Text>
                          <FaSearch />
                        </InputGroup.Text>
                        <FormControl
                          placeholder="Search users by name or email"
                          value={searchQuery}
                          onChange={handleSearch}
                        />
                      </InputGroup>
                    </Col>

                    <Col md={4} lg={2}>
                      <Form.Select
                        value={filterStatus}
                        onChange={(e) => handleFilterChange(e.target.value)}
                        aria-label="Filter users by account status"
                      >
                        <option value="all">All Status</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </Form.Select>
                    </Col>

                    <Col md={4} lg={2}>
                      <Form.Select
                        value={filterEmailStatus}
                        onChange={(e) => handleEmailStatusFilterChange(e.target.value)}
                        aria-label="Filter users by email verification status"
                      >
                        <option value="all">All Email Status</option>
                        <option value="1">Verified</option>
                        <option value="0">Unverified</option>
                      </Form.Select>
                    </Col>

                    <Col md={4} lg={2}>
                      <Form.Select
                        value={filterRole}
                        onChange={(e) => setFilterRole(e.target.value)}
                      >
                        <option value="all">All Roles</option>
                        <option value="admin">Admin</option>
                        <option value="user">User</option>
                      </Form.Select>
                    </Col>

                    <Col md={4} lg={3}>
                      <Form.Select
                        value={filterCountry}
                        onChange={(e) => setFilterCountry(e.target.value)}
                      >
                        <option value="all">All Countries</option>
                        <option value="Algeria">Algeria</option>
                        <option value="Morocco">Morocco</option>
                        <option value="Tunisia">Tunisia</option>
                        <option value="Libya">Libya</option>
                        <option value="Other">Other</option>
                      </Form.Select>
                    </Col>
                  </Row>

                  <Row className="mt-3 g-3">
                    <Col md={6} lg={4}>
                      <Form.Group>
                        <Form.Label className="d-flex align-items-center">
                          <FaCalendarAlt className="me-1" /> Subscription End Date Range
                        </Form.Label>
                        <Row>
                          <Col>
                            <Form.Control
                              type="date"
                              value={filterSubscriptionRange.startDate}
                              onChange={(e) => setFilterSubscriptionRange({
                                ...filterSubscriptionRange,
                                startDate: e.target.value
                              })}
                              placeholder="From"
                            />
                          </Col>
                          <Col>
                            <Form.Control
                              type="date"
                              value={filterSubscriptionRange.endDate}
                              onChange={(e) => setFilterSubscriptionRange({
                                ...filterSubscriptionRange,
                                endDate: e.target.value
                              })}
                              placeholder="To"
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                    </Col>

                    <Col md={6} lg={4}>
                      <Form.Group>
                        <Form.Label className="d-flex align-items-center">
                          <FaCalendarAlt className="me-1" /> User Created Date Range
                        </Form.Label>
                        <Row>
                          <Col>
                            <Form.Control
                              type="date"
                              value={filterCreatedRange.startDate}
                              onChange={(e) => setFilterCreatedRange({
                                ...filterCreatedRange,
                                startDate: e.target.value
                              })}
                              placeholder="From"
                            />
                          </Col>
                          <Col>
                            <Form.Control
                              type="date"
                              value={filterCreatedRange.endDate}
                              onChange={(e) => setFilterCreatedRange({
                                ...filterCreatedRange,
                                endDate: e.target.value
                              })}
                              placeholder="To"
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                    </Col>

                    <Col md={4} lg={4} className="d-flex justify-content-md-end align-items-end gap-2">
                      <CSVLink
                        data={users}
                        filename="filtered-users.csv"
                        className="btn btn-success"
                      >
                        <FaFileDownload className="me-1" /> Export Filtered
                      </CSVLink>

                      <Button 
                        variant="primary"
                        onClick={fetchAllUsersForExport}
                        disabled={exportLoading}
                      >
                        {exportLoading ? (
                          <>
                            <Spinner size="sm" animation="border" className="me-1" /> Exporting...
                          </>
                        ) : (
                          <>
                            <FaFileDownload className="me-1" /> Export All Users
                          </>
                        )}
                      </Button>
                      <CSVLink
                        id="export-all-csv-link"
                        data={allUsers}
                        filename="all-users.csv"
                        className="d-none"
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="shadow-sm">
        <Card.Header className="bg-white py-3">
          <PaginationControls />
        </Card.Header>
        <Card.Body className="p-0">
          <div className="table-responsive">
            <Table hover className="mb-0">
              <thead className="bg-light">
                <tr>
                  <th className="cursor-pointer" onClick={() => handleSort('id')}>
                    ID {getSortIcon('id')}
                  </th>
                  <th className="cursor-pointer" onClick={() => handleSort('name')}>
                    Name {getSortIcon('name')}
                  </th>
                  <th className="cursor-pointer" onClick={() => handleSort('email')}>
                    Email {getSortIcon('email')}
                  </th>
                  <th className="cursor-pointer" onClick={() => handleSort('email_verified')}>
                    Email Status {getSortIcon('email_verified')}
                  </th>
                  <th className="cursor-pointer" onClick={() => handleSort('phoneNumber')}>
                    Phone Number {getSortIcon('phoneNumber')}
                  </th>
                  <th className="cursor-pointer" onClick={() => handleSort('country')}>
                    Country {getSortIcon('country')}
                  </th>
                  <th className="cursor-pointer" onClick={() => handleSort('role')}>
                    Role {getSortIcon('role')}
                  </th>
                  <th>Status</th>
                  <th className="cursor-pointer" onClick={() => handleSort('plan')}>
                    Plan {getSortIcon('plan')}
                  </th>
                  <th className="cursor-pointer" onClick={() => handleSort('subscription_end')}>
                    Subscription End {getSortIcon('subscription_end')}
                  </th>
                  <th className="cursor-pointer" onClick={() => handleSort('created_at')}>
                    Created At {getSortIcon('created_at')}
                  </th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="12" className="text-center py-4">
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </td>
                  </tr>
                ) : users.length > 0 ? (
                  users.map(user => (
                    <tr key={user.id}>
                      <td>{user.id}</td>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{renderEmailVerificationBadge(user)}</td>
                      <td>
                        {user.phoneNumber}
                        {user.phoneNumber && (
                          <Button
                            variant="success"
                            size="sm"
                            className="ms-2"
                            onClick={() => openWhatsApp(user.phoneNumber)}
                            title="Contact via WhatsApp"
                          >
                            <FaWhatsapp />
                          </Button>
                        )}
                      </td>
                      <td>{user.country || '-'}</td>
                      <td>
                        <Badge bg={user.role === 'admin' ? 'dark' : 'info'}>
                          {user.role || 'User'}
                        </Badge>
                      </td>
                      <td>{renderUserStatusBadge(user)}</td>
                      <td>
                        {user.plan ? (
                          <Badge bg="primary">{user.plan}</Badge>
                        ) : (
                          <Badge bg="secondary">No Plan</Badge>
                        )}
                      </td>
                      <td>{formatDate(user.subscription_end)}</td>
                      <td>{formatDate(user.created_at)}</td>
                      <td className="text-center">
                        <Dropdown>
                          <Dropdown.Toggle variant="light" size="sm" id={`dropdown-${user.id}`}>
                            <FaEllipsisV />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {!user.email_verified && (
                              <Dropdown.Item onClick={() => handleVerifyUser(user.id)}>
                                <FaCheckCircle className="me-2 text-success" /> Verify Email
                              </Dropdown.Item>
                            )}
                            <Dropdown.Item onClick={() => handleDisableUser(user.id, user.active)}>
                              {user.active ? (
                                <>
                                  <FaBan className="me-2 text-danger" /> Disable Account
                                </>
                              ) : (
                                <>
                                  <FaCheckCircle className="me-2 text-success" /> Enable Account
                                </>
                              )}
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => setRenewModalInfo({ show: true, userId: user.id, planType: 'monthly' })}>
                              <FaSync className="me-2 text-primary" /> Renew Plan
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => setPaymentModalInfo({ show: true, userId: user.id, userName: user.name })}>
                              <FaCreditCard className="me-2 text-warning" /> Grant Payment
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={() => handleConnectAsUser(user.id)}>
                              <FaUserShield className="me-2 text-info" /> Connect as User
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="12" className="text-center py-4">
                      <p className="mb-0 text-muted">No users found</p>
                      {(searchQuery || filterStatus !== 'all' || filterEmailStatus !== 'all' || 
                        filterRole !== 'all' || filterCountry !== 'all' || 
                        filterSubscriptionRange.startDate || filterSubscriptionRange.endDate ||
                        filterCreatedRange.startDate || filterCreatedRange.endDate) && (
                        <Button
                          variant="link"
                          onClick={handleResetFilters}
                          className="p-0"
                        >
                          Clear all filters
                        </Button>
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-center py-3">
          <PaginationControls />
        </Card.Footer>
      </Card>

      <Modal
        show={renewModalInfo.show}
        onHide={() => setRenewModalInfo({ show: false, userId: null, planType: null })}
      >
        <Modal.Header closeButton>
          <Modal.Title>Renew User Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to renew this user's plan?</p>
          <div className="mb-3">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="planType"
                id="monthly"
                value="monthly"
                checked={renewModalInfo.planType === 'monthly'}
                onChange={() => setRenewModalInfo({ ...renewModalInfo, planType: 'monthly' })}
              />
              <label className="form-check-label" htmlFor="monthly">
                Monthly Plan
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="planType"
                id="yearly"
                value="yearly"
                checked={renewModalInfo.planType === 'yearly'}
                onChange={() => setRenewModalInfo({ ...renewModalInfo, planType: 'yearly' })}
              />
              <label className="form-check-label" htmlFor="yearly">
                Yearly Plan
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button 
            variant="secondary" 
            onClick={() => setRenewModalInfo({ show: false, userId: null, planType: null })}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button 
            variant="primary" 
            onClick={handleRenewPlan}
            disabled={loading}
          >
            {loading ? (
              <>
                <Spinner size="sm" animation="border" className="me-1" />
                Renewing...
              </>
            ) : 'Confirm Renewal'}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={paymentModalInfo.show}
        onHide={() => setPaymentModalInfo({ show: false, userId: null, userName: '' })}
      >
        <Modal.Header closeButton>
          <Modal.Title>Grant Monthly Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="alert alert-info">
            <p className="mb-1"><strong>You are about to grant a monthly payment starting today to:</strong></p>
            <p className="mb-0">{paymentModalInfo.userName}</p>
          </div>
          <p>This will:</p>
          <ul>
            <li>Start a new monthly subscription period from today</li>
            <li>Grant immediate access to all paid features</li>
            <li>Override any existing plan the user might have</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button 
            variant="secondary" 
            onClick={() => setPaymentModalInfo({ show: false, userId: null, userName: '' })}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button 
            variant="success" 
            onClick={handleGrantPayment}
            disabled={loading}
          >
            {loading ? (
              <>
                <Spinner size="sm" animation="border" className="me-1" />
                Granting...
              </>
            ) : 'Grant Payment'}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Users;