import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../utils/axios';
import { Container, Row, Col, Alert, Button } from 'react-bootstrap';

const EmailVerificationConfirm = () => {
  const [statusMessage, setStatusMessage] = useState('');
  const navigate = useNavigate();
  const { token } = useParams(); // Extract the token from the URL path

  useEffect(() => {
    const verifyEmail = async () => {
      if (!token) {
        setStatusMessage('Invalid or missing token.');
        return;
      }

      try {
        await axios.post('/verify-email', { token });
        setStatusMessage('Thank you for verifying your email!');
      } catch (error) {
        setStatusMessage('Verification failed. Please try again or contact support.');
      }
    };

    verifyEmail();
  }, [token]);

  return (
    <Container className="center-container">
      <Row className="justify-content-md-center">
        <Col md={10}>
          <div className="form-container">
            <h1 className="text-center">Email Verification</h1>
            {statusMessage && <Alert variant='info'>{statusMessage}</Alert>}
            <Button variant="primary" className="w-100" onClick={() => navigate('/')}>
              Back to Login
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default EmailVerificationConfirm;