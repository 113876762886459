import React, { useState } from 'react';
import { Card, Nav, Table, Row, Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import formatNumberWithCurrency from '../../utils/formatNumberWithCurrency';

const SimulationPreview = ({ simulation, feeVariations, filteredEmployeesByRole, handleEmployeeChange }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('order');

  const feeVariationName =
    feeVariations.find((fv) => fv.id === simulation.fee_variation_id)?.name || 'N/A';

  const netProfitClass = simulation.net_profit >= 0 ? 'positive' : 'negative';
  const roiClass = simulation.roi_per_order >= 0 ? 'positive' : 'negative';
  const roasClass = simulation.roas_per_order >= 0 ? 'positive' : 'negative';

  const renderTable = () => {
    if (activeTab === 'order') {
      return (
        <>
          <Table responsive bordered striped hover className="custom-table mb-4">
            <thead>
              <tr>
                <th className="th-label-simulation">{t('SIMULATION.LABEL')}</th>
                <th>{t('SIMULATION.PRICE')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td-label">{t('SIMULATION.PRODUCT_PRICE')}</td>
                <td className="table-amount">
                  {formatNumberWithCurrency(simulation.product_price, 'DZD')}
                </td>
              </tr>
              <tr>
                <td className="td-label">{t('SIMULATION.SALE_PRICE')}</td>
                <td className="table-amount">
                  {formatNumberWithCurrency(simulation.sale_price, 'DZD')}
                </td>
              </tr>
              <tr>
                <td className="td-label">{t('SIMULATION.DELIVERY_PRICE')}</td>
                <td className="table-amount">
                  {formatNumberWithCurrency(simulation.delivery_price, 'DZD')}
                </td>
              </tr>
              <tr>
                <td className="td-label">{t('SIMULATION.PACKAGING_FEE')}</td>
                <td className="table-amount">
                  {formatNumberWithCurrency(simulation.packaging_fee, 'DZD')}
                </td>
              </tr>
              <tr>
                <td className="td-label">{t('SIMULATION.CONFIRMATION_COST_PER_ORDER')}</td>
                <td className="table-amount">
                  {formatNumberWithCurrency(simulation.confirmation_cost_per_order, 'DZD')}
                </td>
              </tr>
              <tr>
                <td className="td-label">{t('SIMULATION.DELIVERY_COST_PER_ORDER')}</td>
                <td className="table-amount" style={{ color: 'green' }}>
                  {formatNumberWithCurrency(simulation.delivery_cost_per_order, 'DZD')}
                </td>
              </tr>
              <tr>
                <td className="td-label">{t('SIMULATION.RETURN_FEE_PER_ORDER')}</td>
                <td className="table-amount">
                  {formatNumberWithCurrency(simulation.return_fee_per_order, 'DZD')}
                </td>
              </tr>
              <tr>
                <td className="td-label">{t('SIMULATION.COD_FEE_PER_ORDER')}</td>
                <td className="table-amount">
                  {formatNumberWithCurrency(simulation.cod_fee_per_order, 'DZD')}
                </td>
              </tr>
              <tr>
                <td className="td-label">{t('SIMULATION.OTHER_FEE_PER_ORDER')}</td>
                <td className="table-amount">
                  {formatNumberWithCurrency(simulation.other_fee_per_order, 'DZD')}
                </td>
              </tr>
              <tr>
                <td className="td-label">{t('SIMULATION.FEE_ROLE_PER_ORDER')}</td>
                <td className="table-amount">
                  {formatNumberWithCurrency(simulation.fee_role_per_order, 'DZD')}
                </td>
              </tr>
            </tbody>
          </Table>

          <div className="footer">
            <span className="total-profit">{t('SIMULATION.NET_PROFIT')}</span>
            <span className={`total-amount-${netProfitClass}`}>
              {formatNumberWithCurrency(simulation.net_profit)}
            </span>
          </div>
          <div className="footer">
            <span className="total-profit">{t('SIMULATION.ROI')}</span>
            <span className={`total-amount-${roiClass}`}>
              {formatNumberWithCurrency(simulation.roi_per_order, '%')}
            </span>
          </div>
          <div className="footer">
            <span className="total-profit">{t('SIMULATION.ROAS')}</span>
            <span className={`total-amount-${roasClass}`}>
              {formatNumberWithCurrency(simulation.roas_per_order, '')}
            </span>
          </div>
        </>
      );
    } else if (activeTab === 'time') {
      return (
        <>
          <Table responsive bordered striped hover className="custom-table mb-4">
            <thead>
              <tr>
                <th className="th-label-simulation">{t('SIMULATION.LABEL')}</th>
                <th>{t('SIMULATION.DAILY')}</th>
                <th>{t('SIMULATION.MONTHLY')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td-label">{t('SIMULATION.ADS_SPEND')}</td>
                <td className="table-amount">
                  {formatNumberWithCurrency(simulation.daily_ads_spend, '$')}
                </td>
                <td className="table-amount">
                  {formatNumberWithCurrency(simulation.monthly_ads_spend, '$')}
                </td>
              </tr>
              <tr>
                <td className="td-label">{t('SIMULATION.CONFIRMED_ORDERS')}</td>
                <td className="table-amount">
                  {formatNumberWithCurrency(simulation.confirmed_orders_daily, 'Orders')}
                </td>
                <td className="table-amount">
                  {formatNumberWithCurrency(simulation.confirmed_orders_monthly, 'Orders')}
                </td>
              </tr>
              <tr>
                <td className="td-label">{t('SIMULATION.DELIVERED_ORDERS')}</td>
                <td className="table-amount">
                  {formatNumberWithCurrency(simulation.delivered_orders_daily, 'Orders')}
                </td>
                <td className="table-amount">
                  {formatNumberWithCurrency(simulation.delivered_orders_monthly, 'Orders')}
                </td>
              </tr>
              <tr>
                <td className="td-label">{t('SIMULATION.RETURN_FEE')}</td>
                <td className="table-amount">
                  {formatNumberWithCurrency(simulation.return_fee_daily)}
                </td>
                <td className="table-amount">
                  {formatNumberWithCurrency(simulation.return_fee_monthly)}
                </td>
              </tr>
              <tr>
                <td className="td-label">{t('SIMULATION.COD_FEE')}</td>
                <td className="table-amount">
                  {formatNumberWithCurrency(simulation.cod_fee_daily)}
                </td>
                <td className="table-amount">
                  {formatNumberWithCurrency(simulation.cod_fee_monthly)}
                </td>
              </tr>
              <tr>
                <td className="td-label">{t('SIMULATION.TOOLS_FEE')}</td>
                <td className="table-amount">
                  {formatNumberWithCurrency(simulation.tools_fee_daily)}
                </td>
                <td className="table-amount">
                  {formatNumberWithCurrency(simulation.tools_fee_monthly)}
                </td>
              </tr>
              <tr>
                <td className="td-label">{t('SIMULATION.FIXED_FEE')}</td>
                <td className="table-amount">
                  {formatNumberWithCurrency(simulation.fixed_fee_daily)}
                </td>
                <td className="table-amount">
                  {formatNumberWithCurrency(simulation.fixed_fee_monthly)}
                </td>
              </tr>
              <tr>
                <td className="td-label">{t('SIMULATION.EMPLOYEE_WAGE')}</td>
                <td className="table-amount">
                  {formatNumberWithCurrency(simulation.employee_salary_daily)}
                </td>
                <td className="table-amount">
                  {formatNumberWithCurrency(simulation.employee_salary_monthly)}
                </td>
              </tr>
            </tbody>
          </Table>

          <div className="footer-table">
            <Table responsive borderless hover className="custom-table mb-4">
              <tbody>
                <tr>
                  <td className="td-label total-profit" style={{ width: '200px' }}>
                    {t('SIMULATION.NET_PROFIT')}
                  </td>
                  <td className={`total-amount-${netProfitClass}`}>
                    {formatNumberWithCurrency(simulation.net_profit_daily ?? 0)}
                  </td>
                  <td className={`total-amount-${netProfitClass}`}>
                    {formatNumberWithCurrency(simulation.net_profit_monthly ?? 0)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </>
      );
    }
  };

  return (
    <Card className="mb-4 preview custom-card">

      <Card.Body>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <div className="d-flex justify-content-between align-items-center mb-0">
            <Card.Title className="mb-0">{simulation.name}</Card.Title>
            <span
              className={`badge ${simulation.type === 'test'
                ? 'badge-bg-test'
                : 'badge-bg-product'
                } ms-2`}
            >
              {simulation.type === 'test'
                ? t('SIMULATION.TEST')
                : t('SIMULATION.PRODUCT')
              }
            </span>
          </div>
        </div>
        <small className="text-muted">
                          {simulation.type === 'product' ? (
                            <span className="mb-0 ml-2 d-flex align-items-center">
                              {t('SIMULATION.PRODUCT_FEE_SHARING')}
                            </span>
                          ) : ""}
                        </small>
                        
        <p>
          <strong>{t('SIMULATION.FEES')}:</strong> {feeVariationName}
        </p>
        <div className="scroll-container mb-3">
          <Row className="flex-nowrap">
            <Col className="scroll-item">
              <Card className="sum-card mb-2 simulation-gradient-card">
                <Card.Body>
                  <Card.Text className="text">
                    <p>
                      <span className={`total-amount-${netProfitClass}`}>
                        {formatNumberWithCurrency(simulation.net_profit ?? 0)}
                      </span>
                    </p>
                    <div className="text">{t('SIMULATION.NET_PROFIT')}</div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className="scroll-item">
              <Card className="sum-card mb-2 simulation-gradient-card">
                <Card.Body>
                  <Card.Text className="text">
                    <p>
                      <span className={`total-amount-${netProfitClass}`}>
                        {formatNumberWithCurrency(simulation.net_profit_daily ?? 0)} / Day
                      </span>
                    </p>
                    <div>{t('SIMULATION.NET_PROFIT_DAILY')}</div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className="scroll-item">
              <Card className="sum-card mb-2 simulation-gradient-card">
                <Card.Body>
                  <Card.Text className="text">
                    <p>
                      <span className={`total-amount-${netProfitClass}`}>
                        {formatNumberWithCurrency(simulation.net_profit_monthly ?? 0)}
                      </span>
                    </p>
                    <div>{t('SIMULATION.NET_PROFIT_MONTHLY')}</div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className="scroll-item">
              <Card className="sum-card mb-2 simulation-gradient-card">
                <Card.Body>
                  <Card.Text className="text">
                    <p>
                      <span className={`total-amount-${roiClass}`}>
                        {formatNumberWithCurrency(simulation.roi_per_order ?? 0, '%')}
                      </span>
                    </p>
                    <div>ROI</div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className="scroll-item">
              <Card className="sum-card mb-2 simulation-gradient-card">
                <Card.Body>
                  <Card.Text className="text">
                    <p>
                      <span className={`total-amount-${roasClass}`}>
                        {formatNumberWithCurrency(simulation.roas_per_order ?? 0, '')}
                      </span>
                    </p>
                    <div>ROAS</div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
        <Nav variant="tabs" activeKey={activeTab} onSelect={setActiveTab} className="nav-tabs mt-3">
          <Nav.Item>
            <Nav.Link eventKey="order">{t('SIMULATION.PER_ORDER')}</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="time">{t('SIMULATION.PER_PERIOD')}</Nav.Link>
          </Nav.Item>
        </Nav>

        <div className="tab-content">{renderTable()}</div>

        {/* Employee Selection Section */}
        {Object.keys(filteredEmployeesByRole).length > 0 && (
          <div className="mt-4">
            <p className="mt-3">{t('SIMULATION.EMPLOYEE_ROLES')}</p>
            <Table responsive bordered striped hover className="custom-table mb-4">
              <thead>
                <tr>
                  <th className="th-label-simulation">{t('SIMULATION.ROLE')}</th>
                  <th>{t('SIMULATION.EMPLOYEE')}</th>
                  <th>{t('SIMULATION.VARIABLE_AMOUNT')}</th>
                </tr>
              </thead>
              <tbody>
                {/* {Object.entries(filteredEmployeesByRole).map(([role, employees]) => (
                  <tr key={role}>
                    <td className="td-label">{role}</td>
                    <td>
                      {simulation.selected_employees && 
                      (<Form.Control
                        as="select"
                        value={simulation.selected_employees.find((e) => e.role === role)?.employeeId || ''}
                        onChange={(e) => handleEmployeeChange(role, e.target.value)}
                        className="form-control-custom"
                      >
                        <option value="">{t('SIMULATION.SELECT_EMPLOYEE')}</option>
                        {employees.map((emp) => (
                          <option key={emp.id} value={emp.id}>
                            {emp.name}
                          </option>
                        ))}
                      </Form.Control>
                      )}
                    </td>
                    <td className="table-amount">
                      {simulation.selected_employees && simulation.selected_employees.find((e) => e.role === role)
                        ? formatNumberWithCurrency(
                          employees.find((e) => e.id === parseInt(simulation.selected_employees.find((emp) => emp.role === role).employeeId)).variable_amount,
                          'DZD'
                        )
                        : '-'}
                    </td>
                  </tr>
                ))} */}
                {Object.entries(filteredEmployeesByRole).map(([role, employees]) => {
                  const isArray = Array.isArray(simulation.selected_employees);
                  const selectedEmployee = isArray ? simulation.selected_employees.find((e) => e.role === role) : null;
                  const employeeData = selectedEmployee?.employeeId
                    ? employees.find((e) => e.id === parseInt(selectedEmployee.employeeId))
                    : null;

                  return (
                    <tr key={role}>
                      <td className="td-label">{role}</td>
                      <td>
                        {isArray && (
                          <Form.Control
                            as="select"
                            value={selectedEmployee?.employeeId || ''}
                            onChange={(e) => handleEmployeeChange(role, e.target.value)}
                            className="form-control-custom"
                          >
                            <option value="">{t('SIMULATION.SELECT_EMPLOYEE')}</option>
                            {employees.map((emp) => (
                              <option key={emp.id} value={emp.id}>
                                {emp.name}
                              </option>
                            ))}
                          </Form.Control>
                        )}
                      </td>
                      <td className="table-amount">
                        {employeeData ? formatNumberWithCurrency(employeeData.variable_amount, 'DZD') : '-'}
                      </td>
                    </tr>
                  );
                })}

              </tbody>
            </Table>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default SimulationPreview;