import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { Bar, Pie, Line } from 'react-chartjs-2';
import axios from '../../utils/axios';
import 'bootstrap/dist/css/bootstrap.min.css';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, BarElement, Title, Tooltip, Legend, ArcElement);

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [stats, setStats] = useState({
    totalPayments: 0,
    activeSubscriptions: 0,
    totalRevenueDZD: 0,
    totalRevenueUSD: 0,
    totalRevenueEUR: 0,
    pendingPayments: 0,
  });
  const [paidToday, setPaidToday] = useState({ USD: 0, DZD: 0, EUR: 0 });
  const [monthlyPayments, setMonthlyPayments] = useState([]);
  const [verifiedNotPurchased, setVerifiedNotPurchased] = useState(0);
  const [notRenewed, setNotRenewed] = useState(0);
  const [neverPaid, setNeverPaid] = useState(0);
  const [verifiedNotPurchasedMonthly, setVerifiedNotPurchasedMonthly] = useState([]);
  const [notRenewedMonthly, setNotRenewedMonthly] = useState([]);
  const [neverPaidMonthly, setNeverPaidMonthly] = useState([]);
  const [activeSubscriptionsMonthly, setActiveSubscriptionsMonthly] = useState([]); // New state for monthly subscriptions
  const [paymentMethods, setPaymentMethods] = useState({});
  const [planTypes, setPlanTypes] = useState({});
  const [timeFilter, setTimeFilter] = useState('all'); // Time filter state

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {

        const verifiedNotPurchasedResp = await axios.get('/user/verified-not-purchased');
        setVerifiedNotPurchased(verifiedNotPurchasedResp.data.count);

        const verifiedNotPurchasedMonthlyResp = await axios.get('/user/verified-not-purchased-monthly');
        setVerifiedNotPurchasedMonthly(verifiedNotPurchasedMonthlyResp.data);


        const notRenewedResp = await axios.get('/user/not-renewed');
        setNotRenewed(notRenewedResp.data.count);

        const notRenewedMonthlyResp = await axios.get('/user/not-renewed-monthly');
        setNotRenewedMonthly(notRenewedMonthlyResp.data);


        const neverPaidResp = await axios.get('/user/never-paid');
        setNeverPaid(neverPaidResp.data.count);

        const neverPaidMonthlyResp = await axios.get('/user/never-paid-monthly');
        setNeverPaidMonthly(neverPaidMonthlyResp.data);

        const activeSubscriptionsMonthlyResp = await axios.get('/dashboard/active-subscriptions-monthly');
        setActiveSubscriptionsMonthly(activeSubscriptionsMonthlyResp.data);

        const statsResp = await axios.get('/payments/stats');
        setStats(statsResp.data);

        const todayResp = await axios.get('/dashboard/paid-today');
        setPaidToday(todayResp.data);

        const monthlyResp = await axios.get('/dashboard/paid-by-month');
        setMonthlyPayments(monthlyResp.data);

        const paymentMethodsResp = await axios.get('/dashboard/payment-methods');
        setPaymentMethods(paymentMethodsResp.data);

        const planTypesResp = await axios.get('/dashboard/plan-types');
        setPlanTypes(planTypesResp.data);

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching dashboard data', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const formatNumber = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const filterDataByTime = (data) => {
    if (timeFilter === 'all') return data;
    const now = new Date();
    const monthsBack = {
      '3m': 3,
      '6m': 6,
      '12m': 12,
    }[timeFilter];
    if (!monthsBack) return data;

    const cutoffDate = new Date(now.setMonth(now.getMonth() - monthsBack));
    return data.filter(item => {
      const [year, month] = item.month.split('-').map(Number);
      const itemDate = new Date(year, month - 1);
      return itemDate >= cutoffDate;
    });
  };

  const filterPieDataByTime = (data) => {
    // For pie charts, we'd ideally need a time-filtered endpoint. Here, we return all data as a placeholder.
    // To filter accurately, modify backend endpoints to accept a time range parameter.
    return data;
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { position: 'top' },
      tooltip: { backgroundColor: 'rgba(0, 0, 0, 0.8)', padding: 12, cornerRadius: 6 },
    },
  };

  const monthlyData = {
    labels: filterDataByTime(monthlyPayments).map(mp => mp.month),
    datasets: [{
      label: 'Payments',
      data: filterDataByTime(monthlyPayments).map(mp => mp.count),
      backgroundColor: 'rgba(13, 110, 253, 0.7)',
      borderColor: 'rgba(13, 110, 253, 1)',
      borderWidth: 1,
      borderRadius: 4,
    }],
  };

  const verifiedNotPurchasedMonthlyData = {
    labels: filterDataByTime(verifiedNotPurchasedMonthly).map(vnpm => vnpm.month),
    datasets: [{
      label: 'Verified Not Purchased',
      data: filterDataByTime(verifiedNotPurchasedMonthly).map(vnpm => vnpm.count),
      backgroundColor: 'rgba(23, 162, 184, 0.7)',
      borderColor: 'rgba(23, 162, 184, 1)',
      borderWidth: 1,
      borderRadius: 4,
    }],
  };

  const notRenewedMonthlyData = {
    labels: filterDataByTime(notRenewedMonthly).map(nrm => nrm.month),
    datasets: [{
      label: 'Not Renewed',
      data: filterDataByTime(notRenewedMonthly).map(nrm => nrm.count),
      backgroundColor: 'rgba(220, 53, 69, 0.7)',
      borderColor: 'rgba(220, 53, 69, 1)',
      borderWidth: 1,
      borderRadius: 4,
    }],
  };

  const neverPaidMonthlyData = {
    labels: filterDataByTime(neverPaidMonthly).map(npm => npm.month),
    datasets: [{
      label: 'Never Paid',
      data: filterDataByTime(neverPaidMonthly).map(npm => npm.count),
      backgroundColor: 'rgba(108, 117, 125, 0.7)',
      borderColor: 'rgba(108, 117, 125, 1)',
      borderWidth: 1,
      borderRadius: 4,
    }],
  };

  const activeSubscriptionsMonthlyData = {
    labels: filterDataByTime(activeSubscriptionsMonthly).map(asm => asm.month),
    datasets: [{
      label: 'Active Subscriptions',
      data: filterDataByTime(activeSubscriptionsMonthly).map(asm => asm.count),
      borderColor: 'rgba(40, 167, 69, 1)',
      backgroundColor: 'rgba(40, 167, 69, 0.2)',
      fill: false,
      tension: 0.1,
    }],
  };

  const methodsData = {
    labels: Object.keys(filterPieDataByTime(paymentMethods)),
    datasets: [{
      data: Object.values(filterPieDataByTime(paymentMethods)),
      backgroundColor: ['#0d6efd', '#6610f2', '#6f42c1', '#d63384', '#198754'],
      borderWidth: 0,
    }],
  };

  const plansData = {
    labels: Object.keys(filterPieDataByTime(planTypes)),
    datasets: [{
      data: Object.values(filterPieDataByTime(planTypes)),
      backgroundColor: ['#0d6efd', '#20c997', '#dc3545', '#ffc107'],
      borderWidth: 0,
    }],
  };

  if (isLoading) {
    return (
      <div className="container-fluid min-vh-100 d-flex align-items-center justify-content-center bg-light">
        <div className="text-center">
          <div className="spinner-border text-primary" role="status" style={{ width: '3rem', height: '3rem' }}>
            <span className="visually-hidden">Loading...</span>
          </div>
          <h5 className="mt-3 text-muted">Loading dashboard data...</h5>
        </div>
      </div>
    );
  }

  return (
    <div className="dashboard bg-light min-vh-100">
      <div className="container-fluid p-4">
        <div className="row mb-4">
          <div className="col-12 d-flex justify-content-between align-items-center">
            <h2 className="mb-0">Dashboard Overview</h2>
            <div className="d-flex align-items-center">
              <select
                className="form-select form-select-sm me-2"
                value={timeFilter}
                onChange={(e) => setTimeFilter(e.target.value)}
                style={{ width: '150px' }}
              >
                <option value="all">All Time</option>
                <option value="3m">Last 3 Months</option>
                <option value="6m">Last 6 Months</option>
                <option value="12m">Last 12 Months</option>
              </select>
              <button type="button" className="btn btn-sm btn-primary" onClick={() => window.location.reload()}>
                <i className="bi bi-arrow-clockwise me-1"></i> Refresh
              </button>
            </div>
          </div>
        </div>

        {/* Stats Cards */}
        <div className="row g-4 mb-4 justify-content-center">
          <div className="col-md-6 col-lg-2">
            <div className="card h-100 border-0 shadow-sm">
              <div className="card-body">
                <div className="d-flex align-items-center mb-3">
                  <div className="bg-primary bg-opacity-10 p-3 rounded me-3">
                    <i className="bi bi-credit-card text-primary fs-4"></i>
                  </div>
                  <h5 className="card-title mb-0">Payments Today</h5>
                </div>
                <h2 className="display-6 fw-bold mb-1">{formatNumber(paidToday.USD + paidToday.DZD + paidToday.EUR)}</h2>
                <small className="text-muted">USD: {formatNumber(paidToday.USD)}, DZD: {formatNumber(paidToday.DZD)}, EUR: {formatNumber(paidToday.EUR)}</small>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-2">
            <div className="card h-100 border-0 shadow-sm">
              <div className="card-body">
                <div className="d-flex align-items-center mb-3">
                  <div className="bg-success bg-opacity-10 p-3 rounded me-3">
                    <i className="bi bi-cash-stack text-success fs-4"></i>
                  </div>
                  <h5 className="card-title mb-0">Active Subscriptions</h5>
                </div>
                <h2 className="display-6 fw-bold mb-1">{formatNumber(stats.activeSubscriptions)}</h2>
                <div className="small text-success">
                  <i className="bi bi-graph-up-arrow me-1"></i>
                  Current paying users
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-2">
            <div className="card h-100 border-0 shadow-sm">
              <div className="card-body">
                <div className="d-flex align-items-center mb-3">
                  <div className="bg-info bg-opacity-10 p-3 rounded me-3">
                    <i className="bi bi-people text-info fs-4"></i>
                  </div>
                  <h5 className="card-title mb-0">Verified Not Purchased</h5>
                </div>
                <h2 className="display-6 fw-bold mb-1">{formatNumber(verifiedNotPurchased)}</h2>
                <div className="small text-info">
                  <i className="bi bi-target me-1"></i>
                  Potential upsell opportunity
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-2">
            <div className="card h-100 border-0 shadow-sm">
              <div className="card-body">
                <div className="d-flex align-items-center mb-3">
                  <div className="bg-warning bg-opacity-10 p-3 rounded me-3">
                    <i className="bi bi-person-x text-warning fs-4"></i>
                  </div>
                  <h5 className="card-title mb-0">Users Not Renewed</h5>
                </div>
                <h2 className="display-6 fw-bold mb-1">{formatNumber(notRenewed)}</h2>
                <div className="small text-warning">
                  <i className="bi bi-exclamation-circle me-1"></i>
                  Retention focus needed
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-2">
            <div className="card h-100 border-0 shadow-sm">
              <div className="card-body">
                <div className="d-flex align-items-center mb-3">
                  <div className="bg-secondary bg-opacity-10 p-3 rounded me-3">
                    <i className="bi bi-person text-secondary fs-4"></i>
                  </div>
                  <h5 className="card-title mb-0">Never Paid</h5>
                </div>
                <h2 className="display-6 fw-bold mb-1">{formatNumber(neverPaid)}</h2>
                <div className="small text-secondary">
                  <i className="bi bi-info-circle me-1"></i>
                  Non-converted users
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Charts - First Row */}
        <div className="row g-4 mb-4">
          <div className="col-lg-6">
            <div className="card border-0 shadow-sm h-100">
              <div className="card-header bg-white py-3">
                <h5 className="card-title mb-0">Monthly Payments Trend</h5>
              </div>
              <div className="card-body">
                <div style={{ height: '300px' }}>
                  <Bar data={monthlyData} options={chartOptions} />
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="card border-0 shadow-sm h-100">
              <div className="card-header bg-white py-3">
                <h5 className="card-title mb-0">Verified Not Purchased by Month</h5>
              </div>
              <div className="card-body">
                <div style={{ height: '300px' }}>
                  <Bar data={verifiedNotPurchasedMonthlyData} options={chartOptions} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row g-4 mb-4">
          <div className="col-12">
            <div className="card border-0 shadow-sm h-100">
              <div className="card-header bg-white py-3">
                <h5 className="card-title mb-0">Monthly Active Subscriptions</h5>
              </div>
              <div className="card-body">
                <div style={{ height: '400px' }}>
                  <Line data={activeSubscriptionsMonthlyData} options={chartOptions} />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Charts - Second Row */}
        <div className="row g-4 mb-4">
          <div className="col-lg-6">
            <div className="card border-0 shadow-sm h-100">
              <div className="card-header bg-white py-3">
                <h5 className="card-title mb-0">Non-Renewals by Month</h5>
              </div>
              <div className="card-body">
                <div style={{ height: '300px' }}>
                  <Bar data={notRenewedMonthlyData} options={chartOptions} />
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="card border-0 shadow-sm h-100">
              <div className="card-header bg-white py-3">
                <h5 className="card-title mb-0">Never Paid by Month</h5>
              </div>
              <div className="card-body">
                <div style={{ height: '300px' }}>
                  <Bar data={neverPaidMonthlyData} options={chartOptions} />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Charts - Third Row (Pie Charts) */}
        <div className="row g-4 mb-4">
          <div className="col-lg-6">
            <div className="card border-0 shadow-sm h-100">
              <div className="card-header bg-white py-3">
                <h5 className="card-title mb-0">Payment Methods</h5>
              </div>
              <div className="card-body">
                <div style={{ height: '300px' }} className="d-flex justify-content-center">
                  <Pie data={methodsData} options={chartOptions} />
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="card border-0 shadow-sm h-100">
              <div className="card-header bg-white py-3">
                <h5 className="card-title mb-0">Plan Distribution</h5>
              </div>
              <div className="card-body">
                <div style={{ height: '300px' }} className="d-flex justify-content-center">
                  <Pie data={plansData} options={chartOptions} />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Revenue Table */}
        <div className="row">
          <div className="col-12">
            <div className="card border-0 shadow-sm">
              <div className="card-header bg-white py-3 d-flex justify-content-between align-items-center">
                <h5 className="card-title mb-0">Revenue Breakdown</h5>
                <button className="btn btn-sm btn-outline-secondary">
                  <i className="bi bi-download me-1"></i> Export
                </button>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-hover align-middle">
                    <thead className="table-light">
                      <tr>
                        <th>Currency</th>
                        <th>Total Revenue</th>
                  
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="bg-primary bg-opacity-10 p-2 rounded me-2">
                              <i className="bi bi-currency-dollar text-primary"></i>
                            </div>
                            USD
                          </div>
                        </td>
                        <td>${formatNumber(stats.totalRevenueUSD)}</td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="bg-secondary bg-opacity-10 p-2 rounded me-2">
                              <i className="bi bi-currency-euro text-secondary"></i>
                            </div>
                            EUR
                          </div>
                        </td>
                        <td>€{formatNumber(stats.totalRevenueEUR)}</td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="bg-info bg-opacity-10 p-2 rounded me-2">
                              <i className="bi bi-cash text-info"></i>
                            </div>
                            DZD
                          </div>
                        </td>
                        <td>{formatNumber(stats.totalRevenueDZD)} DZD</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="bg-dark text-white py-4 mt-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <p className="mb-0">© 2025 Admin Dashboard. All rights reserved.</p>
            </div>
            <div className="col-md-6 text-md-end">
              <p className="mb-0">Version 2.1.0</p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Dashboard;