// client/src/components/Unauthorized.js
import React from 'react';

export default function Unauthorized() {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh', // Full viewport height for centering
      backgroundColor: '#f5f5f5', // Light gray background
      textAlign: 'center',
      padding: '20px',
    },
    heading: {
      color: '#4A00E0', // Primary color
      fontSize: '2.5rem', // Larger heading
      marginBottom: '1rem',
      fontWeight: 'bold',
    },
    message: {
      color: '#333', // Darker gray for contrast
      fontSize: '1.2rem',
      maxWidth: '500px', // Limit width for readability
      marginBottom: '2rem', // Space before button
    },
    button: {
      padding: '10px 20px',
      backgroundColor: '#4A00E0', // Primary color
      color: '#fff', // White text
      border: 'none',
      borderRadius: '5px',
      fontSize: '1rem',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease', // Smooth hover effect
    },
    buttonHover: {
      backgroundColor: '#3700b3', // Slightly darker shade for hover
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Access Denied</h1>
      <p style={styles.message}>
        You do not have permission to access this page.
      </p>
      <button
        style={styles.button}
        onMouseOver={(e) => (e.target.style.backgroundColor = styles.buttonHover.backgroundColor)}
        onMouseOut={(e) => (e.target.style.backgroundColor = styles.button.backgroundColor)}
        onClick={() => window.location.href = '/'} // Redirects to login page
      >
        Back to Login
      </button>
    </div>
  );
}