import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from '../utils/axios';

const SubscriptionContext = createContext();

export const SubscriptionProvider = ({ children }) => {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isTrial, setIsTrial] = useState(false);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState(null);
  const [freeTrialEnd, setFreeTrialEnd] = useState(null); // For free trials
  const [loading, setLoading] = useState(true);

  const fetchSubscriptionStatus = async () => {
    try {
      const response = await axios.get('/check-subscription', { withCredentials: true });
      const { isSubscribed, isTrial, subscriptionEndDate, freeTrialEnd, isDue } = response.data;
      setIsSubscribed(isSubscribed); // Set subscribed status
      setIsTrial(isTrial);           // Set trial status
      setSubscriptionEndDate(subscriptionEndDate);
      setFreeTrialEnd(freeTrialEnd);
    } catch (error) {
      console.error('Error fetching subscription status:', error);
      setIsSubscribed(false);
      setIsTrial(false);             // Reset trial status on error
      setSubscriptionEndDate(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubscriptionStatus();
  }, []);

  return (
    <SubscriptionContext.Provider value={{ isSubscribed, isTrial, subscriptionEndDate, freeTrialEnd, loading, fetchSubscriptionStatus }}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export const useSubscription = () => useContext(SubscriptionContext);