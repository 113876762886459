import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaWhatsapp, FaInstagram, FaYoutube, FaEye, FaEyeSlash, FaGlobe } from 'react-icons/fa';
import { Form, Button, Container, Row, Col, InputGroup, Alert, Dropdown } from 'react-bootstrap';
import { UserContext } from '../../contexts/UserContext';
import { useTranslation } from 'react-i18next';
import axios from '../../utils/axios';
import './Login.css';
import LogoVertical from '../../assets/Logo Vertical Blanc.png'; // For mobile
import LogoHorizontal from '../../assets/Logo Horizontal 1 Blanc.png'; // For desktop

const languageLabels = {
  en: 'English',
  fr: 'Français',
  ar: 'العربية'
};

const Login = () => {
  const [form, setForm] = useState({ email: '', password: '', rememberMe: false });
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { updateUserContext } = useContext(UserContext);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get('/auth/check', { withCredentials: true });
        if (response.data.isAuthenticated) {
          updateUserContext(response.data.user);
          navigate('/dashboard/simulations');
        }
      } catch (error) {}
    };
    checkAuth();
  }, [navigate, updateUserContext]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setForm({ ...form, [name]: type === 'checkbox' ? checked : value });
  };

  const whatsappLink = `https://wa.me/+213560345302?text=${encodeURIComponent('Login Issue')}`;

  const validateForm = () => {
    const newErrors = {};
    if (!form.email) newErrors.email = t('Email is required.');
    if (!form.password) newErrors.password = t('Password is required.');
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    try {
      const response = await axios.post('/auth', { email: form.email, password: form.password, rememberMe: form.rememberMe }, { withCredentials: true });
      const { user, messageKey } = response.data.result;
      setErrorMessage('');
      setSuccessMessage(t(messageKey));
      const [firstName] = (user.name || '').split(' ');
      updateUserContext({ avatar: user.avatar, name: firstName || '' });
      navigate('/dashboard/simulations');
    } catch (error) {
      const messageKey = error.response?.data?.messageKey || 'invalidUsernameOrPassword';
      setErrorMessage(
        <div>
          {t(messageKey)}
          {error.response?.data?.resendLink && (
            <div>
              <Button variant="link" id="ResendVerificationEmailLogin" onClick={resendVerificationEmail}>
                {t('Resend Verification Email')}
              </Button>
            </div>
          )}
        </div>
      );
      setSuccessMessage('');
    }
  };

  const resendVerificationEmail = async () => {
    try {
      await axios.post('/signup/resend-verification', { email: form.email });
      setSuccessMessage(t('Verification email resent. Please check your inbox.'));
      setErrorMessage('');
    } catch (error) {
      setErrorMessage(t('Failed to resend verification email.'));
      setSuccessMessage('');
    }
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    document.documentElement.lang = lng;
    document.documentElement.dir = lng === 'ar' ? 'rtl' : 'ltr';
  };

  return (
    <div className="login-wrapper">
      <Container fluid className="custom-container">
        <Row className="flex-grow-1 w-100 m-0">
          <Col xs={12} md={6} className="custom-sidebar">
            <div className="logo-container">
              <img src={LogoVertical} alt="Cod Pricing Logo Vertical" className="logo-img logo-vertical" />
              <img src={LogoHorizontal} alt="Cod Pricing Logo Horizontal" className="logo-img logo-horizontal" />
            </div>
          </Col>
          <Col xs={12} md={6} className="d-flex align-items-center justify-content-center">
            <div className="custom-form-container">
              <h5 className="text-center mb-4">{t('Login to your account')}</h5>
              {successMessage && <Alert variant="success">{successMessage}</Alert>}
              {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Email')}</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="email"
                      name="email"
                      value={form.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                      autoComplete="email"
                    />
                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Password')}</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      value={form.password}
                      onChange={handleChange}
                      isInvalid={!!errors.password}
                      className="password-input"
                      autoComplete="current-password"
                    />
                    <Button variant="outline-secondary" className="password-toggle" onClick={togglePasswordVisibility}>
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </Button>
                    <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Check
                    type="checkbox"
                    name="rememberMe"
                    label={t('Remember me')}
                    checked={form.rememberMe}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Button variant="primary" type="submit" className="w-100 mb-2 custom-button">
                  {t('Login')}
                </Button>
                <div className="d-flex justify-content-between flex-wrap">
                  <Button variant="link" className="p-0 auth-link" onClick={() => navigate('/forget-password')}>
                    {t('Forgot your Password?')}
                  </Button>
                  <Button variant="link" className="p-0 auth-link" onClick={() => navigate('/signup')}>
                    {t('Create an Account')}
                  </Button>
                </div>
              </Form>
              <div className="mt-4 text-center">
                <p className="mb-1">
                  {t("Can't login?")}{' '}
                  <a href={whatsappLink} className="auth-link" target="_blank" rel="noopener noreferrer">
                    <FaWhatsapp />
                  </a>
                </p>
                <p>
                  {t('Follow us on:')}{' '}
                  <a className="auth-link" href="https://www.instagram.com/codpricing" target="_blank" rel="noopener noreferrer">
                    <FaInstagram />
                  </a>{' '}
                  <a className="auth-link" href="https://www.youtube.com/@CODPricing1" target="_blank" rel="noopener noreferrer">
                    <FaYoutube />
                  </a>
                </p>
                <p>
                  <Button
                    variant="link"
                    className="p-0 m-0 small-text"
                    onClick={() => window.open('/privacy-policy-and-terms-of-use', '_blank')}
                  >
                    {t('Privacy Policy and Terms of Use')}
                  </Button>
                </p>
              </div>
              <div className="d-flex justify-content-end mt-3">
                <Dropdown>
                  <Dropdown.Toggle variant="light" id="language-dropdown" className="custom-dropdown-toggle">
                    <FaGlobe /> {languageLabels[i18n.language]}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {Object.keys(languageLabels).map((lang) => (
                      <Dropdown.Item key={lang} onClick={() => changeLanguage(lang)}>
                        {languageLabels[lang]}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <footer className="footer">
        <p className="footer-text">
          © {new Date().getFullYear()} Cod Pricing. {t('All rights reserved.')}
        </p>
      </footer>
    </div>
  );
};

export default Login;