import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Spinner, Button, Table, Modal, Form, Row, Col, Card, InputGroup, Nav, OverlayTrigger, Tooltip, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTag, faPlus, faSquareCheck, faMoneyBillTransfer, faBox, faMoneyBillWave, faCoins, faTruck, faMoneyCheckDollar, faCalendarDay, faDollarSign, faFilter } from '@fortawesome/free-solid-svg-icons';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa';

import axios from '../../utils/axios';
import './Simulations.css';
import './Stepper.css'
import Slider from 'rc-slider';
import { useTranslation } from 'react-i18next';

const wrapLTR = (text) => <span dir="ltr">{text}</span>;

const Simulations = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [simulations, setSimulations] = useState([]);
  const [feeVariations, setFeeVariations] = useState(
    []
  );
  const [newSimulation, setNewSimulation] = useState({
    name: '',
    description: '',
    product_price: '',
    sale_price: '',
    euro_price: '',
    daily_ads_spend: '',
    cost: '',
    orders: '',
    delivery_price: '',
    confirmation_percentage: 0,
    delivery_percentage: 0,
    cod_fee: '',
    return_fee: '',
    packaging_fee: '',
    type: 'test'
  });
  const [selectedSimulation, setSelectedSimulation] = useState(null);
  const [simulationToDelete, setSimulationToDelete] = useState(null);
  const [enrichedEmployees, setEnrichedEmployees] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [errors, setErrors] = useState({});

  const [activeTab, setActiveTab] = useState('order');
  const steps = [1, 2, 3];
  const [step, setStep] = useState(1);

  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    profitStatus: 'all', // all, positive, negative
    type: 'all', // all, test, product
    feeVariationId: 'all'
  });
  const [isFilterCollapsed, setIsFilterCollapsed] = useState(true);

  const fetchSimulations = async () => {
    try {
      const response = await axios.get('/simulations', { withCredentials: true });
      setSimulations(response.data);
      if (response.data.length > 0) {
        setSelectedSimulation(response.data[0]);
      }
    } catch (error) {
      console.error('Error fetching simulations', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchFeeVariations = async () => {
    try {
      const response = await axios.get('/fee-variations', { withCredentials: true });
      setFeeVariations(response.data);
    } catch (error) {
      console.error('Error fetching fee variations data', error);
    }
  };

  useEffect(() => {
    fetchSimulations();
    fetchFeeVariations();
  }, []);

  const toggleFilterCollapse = () => setIsFilterCollapsed(!isFilterCollapsed);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFilterChange = (filterType, value) => {
    setFilters({
      ...filters,
      [filterType]: value
    });
  };


  const filteredSimulations = simulations.filter(simulation => {
    // Search filter
    const matchesSearch = simulation.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      simulation.description?.toLowerCase().includes(searchTerm.toLowerCase());

    // Profit status filter
    const matchesProfitStatus = filters.profitStatus === 'all' ||
      (filters.profitStatus === 'positive' && simulation.net_profit >= 0) ||
      (filters.profitStatus === 'negative' && simulation.net_profit < 0);

    // Type filter
    const matchesType = filters.type === 'all' || simulation.type === filters.type;

    const matchesFeeVariation = filters.feeVariationId === 'all' || simulation.fee_variation_id === parseInt(filters.feeVariationId);


    return matchesSearch && matchesProfitStatus && matchesType && matchesFeeVariation;
  });

  const handleSliderChange = (value, name) => {
    const updatednewSimulation = { ...newSimulation, [name]: value };
    setNewSimulation(updatednewSimulation);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>{t('SIMULATION.NAME')}</Form.Label>
              <Form.Control
                value={newSimulation.name}
                name="name"
                onChange={handleChange}
                placeholder="Enter simulation name"
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t('SIMULATION.DESCRIPTION')}</Form.Label>
              <Form.Control
                as="textarea"
                value={newSimulation.description}
                name="description"
                onChange={handleChange}
                placeholder="Describe your simulation"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{t('SIMULATION.TYPE')}</Form.Label>
              <div className="d-flex gap-3">
                <Form.Check type="radio" label="Test" name="type" value="test" checked={newSimulation.type === 'test'} onChange={handleChange} />
                <Form.Check type="radio" label="Product" name="type" value="product" checked={newSimulation.type === 'product'} onChange={handleChange} />
              </div>
            </Form.Group>
          </Form>
        );
      case 2:
        return (
          <Row>
            <Col xs={12} md={6}>
              <Form.Group controlId="productPrice" className="form-group-custom">
                <Form.Label> <FontAwesomeIcon icon={faTag} /> {t('SIMULATION.PRODUCT_PRICE')} </Form.Label>
                <InputGroup>
                  <Form.Control
                    type="number"
                    name="product_price"
                    value={newSimulation.product_price}
                    onChange={handleChange}
                    className="form-control-custom"
                  />
                  <InputGroup.Text>DZD</InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="salePrice" className="form-group-custom">
                <Form.Label> <FontAwesomeIcon icon={faCoins} /> {t('SIMULATION.SALE_PRICE')} </Form.Label>
                <InputGroup>
                  <Form.Control
                    type="number"
                    name="sale_price"
                    value={newSimulation.sale_price}
                    onChange={handleChange}
                    className="form-control-custom"
                  />
                  <InputGroup.Text>DZD</InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="cost" className="form-group-custom">
                <Form.Label> <FontAwesomeIcon icon={faMoneyCheckDollar} /> {t('SIMULATION.COST')}</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="number"
                    name="cost"
                    value={newSimulation.cost}
                    onChange={handleChange}
                    className="form-control-custom"
                    isInvalid={!!errors.cost}
                  />
                  <InputGroup.Text>$</InputGroup.Text>
                  <Form.Control.Feedback type="invalid">
                    {errors.cost}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="euroPrice" className="form-group-custom">
                <Form.Label> <FontAwesomeIcon icon={faDollarSign} /> {t('SIMULATION.DOLLAR_PRICE')}</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="number"
                    name="euro_price"
                    value={newSimulation.euro_price}
                    onChange={handleChange}
                    className="form-control-custom"
                    isInvalid={!!errors.euro_price}
                  />
                  <InputGroup.Text>DZD</InputGroup.Text>
                  <Form.Control.Feedback type="invalid">
                    {errors.euro_price}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="dailyAdsSpend" className="form-group-custom">
                <Form.Label> <FontAwesomeIcon icon={faCalendarDay} /> {t('SIMULATION.DOLLAR_SPEND_PER_DAY')} </Form.Label>
                <InputGroup>
                  <Form.Control
                    type="number"
                    name="daily_ads_spend"
                    value={newSimulation.daily_ads_spend}
                    onChange={handleChange}
                    className="form-control-custom"
                    isInvalid={!!errors.daily_ads_spend}
                  />
                  <InputGroup.Text>$</InputGroup.Text>
                  <Form.Control.Feedback type="invalid">
                    {errors.daily_ads_spend}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        );
      case 3:
        return (
          <>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group controlId="delivery_price" className="form-group-custom">
                  <Form.Label><FontAwesomeIcon icon={faMoneyBillTransfer} /> {t('SIMULATION.DELIVERY_PRICE')}</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      name="delivery_price"
                      value={newSimulation.delivery_price}
                      onChange={handleChange}
                      className="form-control-custom"
                    />
                    <InputGroup.Text>DZD</InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Form.Group controlId="packaging_fee" className="form-group-custom">
                  <Form.Label><FontAwesomeIcon icon={faMoneyBillTransfer} /> {t('SIMULATION.PACKAGING_FEE')} </Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      name="packaging_fee"
                      value={newSimulation.packaging_fee}
                      onChange={handleChange}
                      className="form-control-custom"
                    />
                    <InputGroup.Text>DZD</InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Form.Group controlId="confirmationPercentage" className="form-group-custom">
                  <Form.Label><FontAwesomeIcon icon={faSquareCheck} /> {t('SIMULATION.CONFIRMATION_PERCENTAGE')}</Form.Label>
                  <Slider
                    min={0}
                    max={100}
                    value={newSimulation.confirmation_percentage || 0}
                    onChange={(value) => handleSliderChange(value, 'confirmation_percentage')}
                    className="slider-custom"
                  />
                  <Form.Text className="form-text-custom">{newSimulation.confirmation_percentage}%</Form.Text>
                  {errors.confirmation_percentage && (
                    <div className="text-danger mt-1" style={{ fontSize: '0.875rem' }}>
                      {errors.confirmation_percentage}
                    </div>
                  )}
                </Form.Group>
                <Form.Group controlId="deliveryPercentage" className="form-group-custom">
                  <Form.Label><FontAwesomeIcon icon={faTruck} /> {t('SIMULATION.DELIVERY_PERCENTAGE')}</Form.Label>
                  <Slider
                    min={0}
                    max={100}
                    value={newSimulation.delivery_percentage || 0}
                    onChange={(value) => handleSliderChange(value, 'delivery_percentage')}
                    className="slider-custom"
                  />
                  <Form.Text className="form-text-custom">{newSimulation.delivery_percentage}%</Form.Text>
                  {errors.delivery_percentage && (
                    <div className="text-danger mt-1" style={{ fontSize: '0.875rem' }}>
                      {errors.delivery_percentage}
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group controlId="codFee" className="form-group-custom">
                  <Form.Label><FontAwesomeIcon icon={faMoneyBillWave} /> {t('SIMULATION.COD_FEE')}</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      name="cod_fee"
                      value={newSimulation.cod_fee}
                      onChange={handleChange}
                      className="form-control-custom"
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Form.Group controlId="returnFee" className="form-group-custom">
                  <Form.Label><FontAwesomeIcon icon={faBox} /> {t('SIMULATION.RETURN_FEE')}</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      name="return_fee"
                      value={newSimulation.return_fee}
                      onChange={handleChange}
                      className="form-control-custom"
                    />
                    <InputGroup.Text>DZD</InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
          </>
        );
      default:
        return null;
    }
  };

  const validateForm = () => {
    const newErrors = {};

    // Required fields validation
    if (!newSimulation.name.trim()) {
      newErrors.name = t('SIMULATION.NAME_REQUIRED') || 'Name is required';
    }

    if (!newSimulation.cost || parseFloat(newSimulation.cost) <= 0) {
      newErrors.cost = t('SIMULATION.COST_MUST_BE_POSITIVE') || 'Cost must be greater than 0';
    }

    if (!newSimulation.euro_price || parseFloat(newSimulation.euro_price) <= 0) {
      newErrors.euro_price = t('SIMULATION.EURO_PRICE_MUST_BE_POSITIVE') || 'Euro price must be greater than 0';
    }

    if (!newSimulation.daily_ads_spend || parseFloat(newSimulation.daily_ads_spend) <= 0) {
      newErrors.daily_ads_spend = t('SIMULATION.DAILY_SPEND_MUST_BE_POSITIVE') || 'Daily ads spend must be greater than 0';
    }

    if (newSimulation.confirmation_percentage <= 0) {
      newErrors.confirmation_percentage = t('SIMULATION.CONFIRMATION_PERCENTAGE_MUST_BE_POSITIVE') || 'Confirmation percentage must be greater than 0';
    }

    if (newSimulation.delivery_percentage <= 0) {
      newErrors.delivery_percentage = t('SIMULATION.DELIVERY_PERCENTAGE_MUST_BE_POSITIVE') || 'Delivery percentage must be greater than 0';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    let stepErrors = {};
    if (step === 1) {
      if (!newSimulation.name.trim()) {
        stepErrors.name = t('SIMULATION.NAME_REQUIRED') || 'Name is required';
      }
    } else if (step === 2) {
      if (!newSimulation.cost || parseFloat(newSimulation.cost) <= 0) {
        stepErrors.cost = t('SIMULATION.COST_MUST_BE_POSITIVE') || 'Cost must be greater than 0';
      }
      if (!newSimulation.euro_price || parseFloat(newSimulation.euro_price) <= 0) {
        stepErrors.euro_price = t('SIMULATION.EURO_PRICE_MUST_BE_POSITIVE') || 'Euro price must be greater than 0';
      }
      if (!newSimulation.daily_ads_spend || parseFloat(newSimulation.daily_ads_spend) <= 0) {
        stepErrors.daily_ads_spend = t('SIMULATION.DAILY_SPEND_MUST_BE_POSITIVE') || 'Daily ads spend must be greater than 0';
      }
    }
    if (Object.keys(stepErrors).length > 0) {
      setErrors(stepErrors);
      return;
    }
    // Only clear errors for the current step if validation passes
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (step === 1) delete updatedErrors.name;
      if (step === 2) {
        delete updatedErrors.cost;
        delete updatedErrors.euro_price;
        delete updatedErrors.daily_ads_spend;
      }
      return updatedErrors;
    });
    setStep(step + 1);
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const formatNumberWithCurrency = (num, currency = 'DZD') => {
    if (isNaN(num) || num === null) return `0.00 ${currency}`;
    return `${num
      .toFixed(2) // Ensure two decimal places
      .replace(/\d(?=(\d{3})+\.)/g, '$& ') // Add thousands separator
      .replace(/\.00$/, '')} ${currency}`; // Remove decimal places if zero
  };

  const handleAddSimulation = async () => {
    if (!validateForm()) {
      return;
    }
    // Create a copy of newSimulation with numeric fields converted
    const simulationToSubmit = {
      ...newSimulation,
      product_price: newSimulation.product_price === '' ? 0 : parseFloat(newSimulation.product_price) || 0,
      sale_price: newSimulation.sale_price === '' ? 0 : parseFloat(newSimulation.sale_price) || 0,
      euro_price: newSimulation.euro_price === '' ? 0 : parseFloat(newSimulation.euro_price) || 0,
      daily_ads_spend: newSimulation.daily_ads_spend === '' ? 0 : parseFloat(newSimulation.daily_ads_spend) || 0,
      cost: newSimulation.cost === '' ? 1 : parseFloat(newSimulation.cost) || 1,
      orders: newSimulation.orders === '' ? 0 : parseFloat(newSimulation.orders) || 0,
      delivery_price: newSimulation.delivery_price === '' ? 0 : parseFloat(newSimulation.delivery_price) || 0,
      confirmation_percentage: newSimulation.confirmation_percentage === '' ? 1 : parseFloat(newSimulation.confirmation_percentage) || 1,
      delivery_percentage: newSimulation.delivery_percentage === '' ? 1 : parseFloat(newSimulation.delivery_percentage) || 1,
      cod_fee: newSimulation.cod_fee === '' ? 0 : parseFloat(newSimulation.cod_fee) || 0,
      return_fee: newSimulation.return_fee === '' ? 0 : parseFloat(newSimulation.return_fee) || 0,
      packaging_fee: newSimulation.packaging_fee === '' ? 0 : parseFloat(newSimulation.packaging_fee) || 0,
    };
    try {
      const response = await axios.post('/simulations', simulationToSubmit, { withCredentials: true });
      setSimulations([...simulations, response.data]);
      setShowModal(false);
      navigate(`/dashboard/simulations/${response.data.id}`);
    } catch (error) {
      console.error('Error adding simulation', error);
    }
  };

  const handleChange = (e) => {
    setNewSimulation({ ...newSimulation, [e.target.name]: e.target.value });
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`/simulations/${simulationToDelete}`, { withCredentials: true });
      fetchSimulations();
      setSimulations(simulations.filter((sim) => sim.id !== simulationToDelete));
      setShowConfirmModal(false);
    } catch (error) {
      console.error('Error deleting simulation', error);
    }
  };

  const handleSelectSimulation = (simulation) => {
    setSelectedSimulation(simulation);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const netProfitClass = selectedSimulation?.net_profit >= 0 ? 'positive' : 'negative';
  const netProfitClassDaily = selectedSimulation?.net_profit_daily >= 0 ? 'positive' : 'negative';
  const netProfitClassMonthly = selectedSimulation?.net_profit_monthly >= 0 ? 'positive' : 'negative';

  const feeVariation = feeVariations?.find(variation => variation.id === selectedSimulation?.fee_variation_id) || {};

  useEffect(() => {
    const enrichEmployees = () => {
      if (!selectedSimulation) return [];

      const feeVariation = feeVariations?.find(variation => variation.id === selectedSimulation.fee_variation_id) || {};
      const employeeSalaries = feeVariation.EmployeeSalaries || [];
      const enrichedEmployees = Array.isArray(selectedSimulation.selected_employees)
        ? selectedSimulation.selected_employees.map(employee => {
          // Find employee details in employeeSalaries
          const employeeDetails = employeeSalaries.find(emp => emp.id === parseInt(employee.employeeId)) || {};
          // Merge employee with employeeDetails
          return {
            ...employee,
            name: employeeDetails.name,
            variable_amount: employeeDetails.variable_amount || 0, // Provide default amount
            per_order_basis: employeeDetails.per_order_basis || 'N/A', // Provide default basis
            role: employee.role // Ensure we include the role
          };
        })
        : []; // Default to an empty array if selected_employees is not an array

      setEnrichedEmployees(enrichedEmployees);
    };

    enrichEmployees();
  }, [selectedSimulation, feeVariations]);

  const renderTable = () => {
    if (activeTab === 'order') {
      return (
        <>
          <Table responsive bordered striped hover className="custom-table mb-4">
            <thead>
              <tr>
                <th className='th-label-simulation'>{t('SIMULATION.LABEL')}</th>
                <th>{t('SIMULATION.PRICE')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='td-label'>{t('SIMULATION.PRODUCT_PRICE')}</td>
                <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.product_price, 'DZD'))}</td>
              </tr>
              <tr>
                <td className='td-label'>{t('SIMULATION.SALE_PRICE')}</td>
                <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.sale_price, 'DZD'))}</td>
              </tr>
              <tr>
                <td className='td-label'>{t('SIMULATION.DELIVERY_PRICE')}</td>
                <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.delivery_price, 'DZD'))}</td>
              </tr>
              <tr>
                <td className='td-label'>{t('SIMULATION.PACKAGING_FEE')}</td>
                <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.packaging_fee, 'DZD'))}</td>
              </tr>
              <tr>
                <td className='td-label'>{t('SIMULATION.CONFIRMATION_COST_PER_ORDER')}</td>
                <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.confirmation_cost_per_order, 'DZD'))}</td>
              </tr>
              <tr>
                <td className='td-label'>{t('SIMULATION.DELIVERY_COST_PER_ORDER')}</td>
                <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.delivery_cost_per_order, 'DZD'))}</td>
              </tr>
              <tr>
                <td className='td-label'>{t('SIMULATION.RETURN_FEE_PER_ORDER')}</td>
                <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.return_fee_per_order, 'DZD'))}</td>
              </tr>
              <tr>
                <td className='td-label'>{t('SIMULATION.COD_FEE_PER_ORDER')}</td>
                <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.cod_fee_per_order, 'DZD'))}</td>
              </tr>
              <tr>
                <td className='td-label'>{t('SIMULATION.OTHER_FEE_PER_ORDER')}</td>
                <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.other_fee_per_order, 'DZD'))}</td>
              </tr>
              <tr>
                <td className='td-label'>{t('SIMULATION.FEE_ROLE_PER_ORDER')}</td>
                <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.fee_role_per_order, 'DZD'))}</td>
              </tr>
            </tbody>
          </Table>
          {enrichedEmployees && enrichedEmployees.length > 0 && (
          <Table responsive bordered striped hover className="employee-variant-table custom-table mb-4">
          <thead>
            <tr>
              <th>{t('SIMULATION.ROLE')}</th>
              <th>{t('SIMULATION.EMPLOYEE_NAME')}</th>
              <th>{t('SIMULATION.VARIABLE_AMOUNT')}</th>
              <th>{t('SIMULATION.PER_ORDER')}</th>
            </tr>
          </thead>
          <tbody>
            {enrichedEmployees.map((emp, index) => (
              <tr key={index}>
                <td>{emp.role}</td>
                <td>{emp.name}</td>
                <td>{emp.variable_amount ? wrapLTR(`${formatNumberWithCurrency(emp.variable_amount, 'DZD')}`) : 'N/A'}</td>
                <td>{wrapLTR(t(`SIMULATION.PER_ORDER_BASIS_${emp.per_order_basis}`))}</td>
              </tr>
            ))}
          </tbody>
        </Table>
          )}
          <div className="footer">
            <span className="total-profit">{t('SIMULATION.NET_PROFIT')}</span>
            <span className={`total-amount-${netProfitClass}`}>{wrapLTR(formatNumberWithCurrency(selectedSimulation.net_profit))}</span>
          </div>
          <div className="footer">
            <span className="total-profit">{t('SIMULATION.ROI')}</span>
            <span className={`total-amount-${netProfitClass}`}>{wrapLTR(formatNumberWithCurrency(selectedSimulation.roi_per_order, '%'))}</span>
          </div>
          <div className="footer">
            <span className="total-profit">{t('SIMULATION.ROAS')}</span>
            <span className={`total-amount-${netProfitClass}`}>{wrapLTR(formatNumberWithCurrency(selectedSimulation.roas_per_order, ''))}</span>
          </div>
        </>
      );
    } else if (activeTab === 'time') {
      return (
        <>
          <Table responsive bordered striped hover className="custom-table mb-4">
            <thead>
              <tr>
                <th className='th-label-simulation'>{t('SIMULATION.LABEL')}</th>
                <th>{t('SIMULATION.DAILY')}</th>
                <th>{t('SIMULATION.MONTHLY')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='td-label'>{t('SIMULATION.ADS_SPEND')}</td>
                <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.daily_ads_spend, '$'))}</td>
                <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.monthly_ads_spend, '$'))}</td>
              </tr>
              <tr>
                <td className='td-label'>{t('SIMULATION.CONFIRMED_ORDERS')}</td>
                <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.confirmed_orders_daily, 'Orders'))}</td>
                <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.confirmed_orders_monthly, 'Orders'))}</td>
              </tr>
              <tr>
                <td className='td-label'>{t('SIMULATION.DELIVERED_ORDERS')}</td>
                <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.delivered_orders_daily, 'Orders'))}</td>
                <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.delivered_orders_monthly, 'Orders'))}</td>
              </tr>
              <tr>
                <td className='td-label'>{t('SIMULATION.RETURN_FEE')}</td>
                <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.return_fee_daily))}</td>
                <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.return_fee_monthly))}</td>
              </tr>
              <tr>
                <td className='td-label'>{t('SIMULATION.COD_FEE')}</td>
                <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.cod_fee_daily))}</td>
                <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.cod_fee_monthly))}</td>
              </tr>
              <tr>
                <td className='td-label'>{t('SIMULATION.TOOLS_FEE')}</td>
                <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.tools_fee_daily))}</td>
                <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.tools_fee_monthly))}</td>
              </tr>
              <tr>
                <td className='td-label'>{t('SIMULATION.FIXED_FEE')}</td>
                <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.fixed_fee_daily))}</td>
                <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.fixed_fee_monthly))}</td>
              </tr>
              <tr>
                <td className='td-label'>{t('SIMULATION.EMPLOYEE_FEE')}</td>
                <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.employee_salary_daily))}</td>
                <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.employee_salary_monthly))}</td>
              </tr>
            </tbody>
          </Table>
          <div className="footer-table">
            <Table responsive borderless hover className="custom-table mb-4">
              <tbody>
                <tr>
                  <td className="td-label total-profit" style={{ width: '200px' }}>{t('SIMULATION.NET_PROFIT')}</td>
                  <td className={`total-amount-${netProfitClassDaily}`}>{wrapLTR(formatNumberWithCurrency(selectedSimulation?.net_profit_daily ?? 0))}</td>
                  <td className={`total-amount-${netProfitClassMonthly}`}>{wrapLTR(formatNumberWithCurrency(selectedSimulation?.net_profit_monthly ?? 0))}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </>
      );
    }
  };

  const renderPreview = () => {
    if (!selectedSimulation) {
      return (
        <Card>
          <Card.Body> {t('SIMULATION.Select a simulation to preview details here')} </Card.Body>
        </Card>
      );
    }
  
    return (
      <Card className="mb-3 preview">
        <Card.Body>
          <h3>{selectedSimulation.name}</h3>
          <div className="mb-3">
            <p>
              <strong>{t('SIMULATION.FEES')}:</strong>{" "}
              {wrapLTR(feeVariation ? feeVariation.name : 'N/A')}
            </p>
          </div>
          <div className="scroll-container mb-3">
            <div className="scroll-content">
              <Card className="sum-card simulation-gradient-card">
                <Card.Body>
                  <Card.Text className="text">
                    <p><span className={`total-amount-${netProfitClass}`}>
                      {formatNumberWithCurrency(selectedSimulation?.net_profit ?? 0)}
                    </span></p>
                    <div>{t('SIMULATION.NET_PROFIT_PER_ORDER')}</div>
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="sum-card simulation-gradient-card">
                <Card.Body>
                  <Card.Text className="text">
                    <p><span className={`total-amount-${netProfitClassDaily}`}>
                      {formatNumberWithCurrency(selectedSimulation?.net_profit_daily ?? 0)} / {t('SIMULATION.DAY')}
                    </span></p>
                    <div>{t('SIMULATION.NET_PROFIT_DAILY')}</div>
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="sum-card simulation-gradient-card">
                <Card.Body>
                  <Card.Text className="text">
                    <p><span className={`total-amount-${netProfitClassMonthly}`}>
                      {formatNumberWithCurrency(selectedSimulation?.net_profit_monthly ?? 0)}
                    </span></p>
                    <div>{t('SIMULATION.NET_PROFIT_MONTHLY')}</div>
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="sum-card simulation-gradient-card">
                <Card.Body>
                  <Card.Text className="text">
                    <p><span className={`total-amount-${netProfitClass}`}>
                      {formatNumberWithCurrency(selectedSimulation?.roi_per_order ?? 0, '%')}
                    </span></p>
                    <div>{t('SIMULATION.ROI')}</div>
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="sum-card simulation-gradient-card">
                <Card.Body>
                  <Card.Text className="text">
                    <p><span className={`total-amount-${netProfitClass}`}>
                      {formatNumberWithCurrency(selectedSimulation?.roas_per_order ?? 0, '')}
                    </span></p>
                    <div>{t('SIMULATION.ROAS')}</div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
          <Nav variant="tabs" activeKey={activeTab}>
            <Nav.Item>
              <Nav.Link eventKey="order" onClick={() => handleTabChange('order')}> {t('SIMULATION.PER_ORDER')}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="time" onClick={() => handleTabChange('time')}> {t('SIMULATION.PER_PERIOD')} </Nav.Link>
            </Nav.Item>
          </Nav>
          <div className="tab-content">
            {renderTable()}
          </div>
        </Card.Body>
      </Card>
    );
  };

  // const renderTooltip = (props) => (
  //   <Tooltip id="fee-sharing-tooltip" {...props} className="custom-tooltip">
  //     {t('SIMULATION.PRODUCT_FEE_SHARING_TOOLTIP')}
  //   </Tooltip>
  // );

  return (
    <Container fluid className="simulations-container py-4">
      {loading ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <Row>
          <Col lg={7}>
            <div className="mb-4">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h1 className="m-0"> {t('SIMULATION.TITLE')} </h1>
                <Button className='new-simulation-button' variant="primary" onClick={() => setShowModal(true)}>
                  <FontAwesomeIcon icon={faPlus} /> {t('SIMULATION.NEW_SIMULATION')}
                </Button>
              </div>

              <div className="search-filter-container mb-4">
                {/* Row 1: Search Bar */}
                <Row className="search-filter-row mb-3">
                  <Col md={12}>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder={t('SIMULATION.SEARCH_PLACEHOLDER') || "Search simulations..."}
                        value={searchTerm}
                        onChange={handleSearchChange}
                      />
                    </InputGroup>
                  </Col>
                </Row>

                {/* Row 2: Filter Title and Clear Button */}
                <Row className="mb-2">
                  <Col xs={12} className="d-flex justify-content-between align-items-center">
                    <span className="filters-title">{t('SIMULATION.FILTERS') || "Filters"}</span>
                    {(filters.profitStatus !== 'all' || filters.type !== 'all' || filters.feeVariationId !== 'all') && (
                      <button
                        className="clear-filters"
                        onClick={() => setFilters({ profitStatus: 'all', type: 'all', feeVariationId: 'all' })}
                      >
                        {t('SIMULATION.CLEAR_ALL') || "Clear all"}
                      </button>
                    )}
                  </Col>
                </Row>



                <Button
                  variant="link"
                  className="filter-toggle-btn d-md-none mb-2"
                  onClick={toggleFilterCollapse}
                  aria-controls="filter-collapse"
                  aria-expanded={!isFilterCollapsed}
                >
                  <FontAwesomeIcon icon={faFilter} /> {isFilterCollapsed ? t('SIMULATION.SHOW_FILTERS') : t('SIMULATION.HIDE_FILTERS')}
                </Button>
                <Collapse in={!isFilterCollapsed}>
                  <div id="filter-collapse">
                    <Row>
                      {/* Row 3: Filter Selects (side by side) */}
                      <Row className="mb-3">
                        <Col xs={12} sm={4} className="mb-2 mb-sm-0">
                          <Form.Select
                            value={filters.profitStatus}
                            onChange={(e) => handleFilterChange('profitStatus', e.target.value)}
                            className="w-100"
                          >
                            <option value="all">{t('SIMULATION.ALL_PROFITS') || "All Profits"}</option>
                            <option value="positive">{t('SIMULATION.POSITIVE_PROFIT') || "Profitable"}</option>
                            <option value="negative">{t('SIMULATION.NEGATIVE_PROFIT') || "Loss"}</option>
                          </Form.Select>
                        </Col>

                        <Col xs={12} sm={4} className="mb-2 mb-sm-0">
                          <Form.Select
                            value={filters.type}
                            onChange={(e) => handleFilterChange('type', e.target.value)}
                            className="w-100"
                          >
                            <option value="all">{t('SIMULATION.ALL_TYPES') || "All Types"}</option>
                            <option value="test">{t('SIMULATION.TEST') || "Test"}</option>
                            <option value="product">{t('SIMULATION.PRODUCT') || "Product"}</option>
                          </Form.Select>
                        </Col>

                        <Col xs={12} sm={4}>
                          <Form.Select
                            value={filters.feeVariationId}
                            onChange={(e) => handleFilterChange('feeVariationId', e.target.value)}
                            className="w-100"
                          >
                            <option value="all">{t('SIMULATION.ALL_FEE_VARIATIONS') || "All Fee Variations"}</option>
                            {feeVariations.map((variation) => (
                              <option key={variation.id} value={variation.id}>{variation.name}</option>
                            ))}
                          </Form.Select>
                        </Col>

                      </Row>    </Row>
                  </div>
                </Collapse>
                {/* Row 4: Filter Pills */}
                {(filters.profitStatus !== 'all' || filters.type !== 'all' || filters.feeVariationId !== 'all') && (
                  <Row>
                    <Col xs={12}>
                      <div className="filter-pills-container">
                        {filters.profitStatus !== 'all' && (
                          <div className="filter-pill">
                            {filters.profitStatus === 'positive'
                              ? t('SIMULATION.POSITIVE_PROFIT') || "Profitable"
                              : t('SIMULATION.NEGATIVE_PROFIT') || "Loss"}
                            <span
                              className="close-icon"
                              onClick={() => handleFilterChange('profitStatus', 'all')}
                            >×</span>
                          </div>
                        )}

                        {filters.type !== 'all' && (
                          <div className="filter-pill">
                            {filters.type === 'test'
                              ? t('SIMULATION.TEST') || "Test"
                              : t('SIMULATION.PRODUCT') || "Product"}
                            <span
                              className="close-icon"
                              onClick={() => handleFilterChange('type', 'all')}
                            >×</span>
                          </div>
                        )}

                        {filters.feeVariationId !== 'all' && (
                          <div className="filter-pill">
                            {feeVariations && feeVariations.length > 0
                              ? (feeVariations.find(v => v.id && filters.feeVariationId && v.id.toString() === filters.feeVariationId.toString())?.name || "Unknown Fee Variation")
                              : "Unknown Fee Variation"
                            }
                            <span
                              className="close-icon"
                              onClick={() => handleFilterChange('feeVariationId', 'all')}
                            >×</span>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                )}
              </div>
            </div>

            {filteredSimulations.length === 0 ? (
              simulations.length === 0 ? (
                <p> {t('SIMULATION.NO_SIMULATIONS')} </p>
              ) : (
                <p>
                  {t('SIMULATION.NO_MATCHING_SIMULATIONS') ||
                    'No simulations match your search criteria.'}
                </p>
              )
            ) : (
              filteredSimulations.map((simulation, id) => {
                // Find the fee variation associated with the current simulation
                const correspondingFeeVariation = feeVariations.find(
                  (variation) => variation.id === simulation.fee_variation_id
                );

                return (
                  <Card
                    key={simulation.id}
                    className={`fee-card mb-3 ${selectedSimulation?.id === simulation.id ? 'selected' : ''
                      } ${simulation.type === 'product' ? 'product-card' : ''}`}
                    onClick={() => handleSelectSimulation(simulation)}
                  >
                    <Card.Body>
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <div className="d-flex justify-content-between align-items-center mb-0">
                          <Card.Title className="mb-0">{simulation.name}</Card.Title>
                          <span
                            className={`badge ${simulation.type === 'test'
                              ? 'badge-bg-test'
                              : 'badge-bg-product'
                              } ms-2`}
                          >
                            {simulation.type === 'test'
                              ? t('SIMULATION.TEST')
                              : t('SIMULATION.PRODUCT')
                            }
                          </span>

                        </div>
                        <div className="action-buttons">
                          <OverlayTrigger overlay={<Tooltip>{t('GENERAL.VIEW')}</Tooltip>}>
                            <Button variant="link"><FaEye /></Button>
                          </OverlayTrigger>
                          <OverlayTrigger overlay={<Tooltip>{t('GENERAL.EDIT')}</Tooltip>}>
                            <Button variant="link" onClick={(e) => { e.stopPropagation(); navigate(`/dashboard/simulations/${simulation.id}`); }}><FaEdit /></Button>
                          </OverlayTrigger>
                          <OverlayTrigger overlay={<Tooltip>{t('GENERAL.DELETE')}</Tooltip>}>
                            <Button variant="link" onClick={(e) => { e.stopPropagation(); setSimulationToDelete(simulation.id); setShowConfirmModal(true); }}><FaTrash /></Button>
                          </OverlayTrigger>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <small className="text-muted">
                          {simulation.type === 'product' ? (
                            <span className="mb-0 ml-2 d-flex align-items-center">
                              {t('SIMULATION.PRODUCT_FEE_SHARING')}
                            </span>
                          ) : ""}
                        </small>
                        <Card className="simulation-fee-insight me-2">
                          <span className="amount">
                            {t('SIMULATION.FEES')}:{' '}
                            {wrapLTR(
                              correspondingFeeVariation
                                ? correspondingFeeVariation.name
                                : 'No Fees'
                            )}
                          </span>
                        </Card>
                      </div>
                      <div className="fee-overview">
                        <div className="fee-overview d-flex flex-wrap">
                          <Card className="simulation-card-insight me-2">
                            <span className="amount">
                              {wrapLTR(
                                formatNumberWithCurrency(simulation.daily_ads_spend, '$')
                              )}
                            </span>
                            <span>
                              {t('SIMULATION.ADS_SPEND')} / {t('SIMULATION.DAY')}
                            </span>
                          </Card>

                          <Card className="simulation-card-insight me-2">
                            <span className="amount">
                              {wrapLTR(formatNumberWithCurrency(simulation.cost, '$'))}
                            </span>
                            <span>{t('SIMULATION.COST')}</span>
                          </Card>

                          <Card className="simulation-card-insight me-2">
                            <span className="amount">
                              {wrapLTR(formatNumberWithCurrency(simulation.delivered_orders_daily, 'Order'))}
                            </span>
                            <span>{t('SIMULATION.DELIVERED_ORDERS')}</span>
                          </Card>
                          <Card className="simulation-card-insight me-2">
                            <span className="amount">
                              {wrapLTR(formatNumberWithCurrency(simulation.net_profit, 'DZD'))}
                            </span>
                            <span>{t('SIMULATION.NET_PROFIT')}</span>
                          </Card>
                          <Card className="simulation-card-insight me-2">
                            <span className="amount">
                              {wrapLTR(formatNumberWithCurrency(simulation.roi_per_order, '%'))}
                            </span>
                            <span>{t('SIMULATION.ROI')}</span>
                          </Card>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                );
              })
            )}

          </Col>
          <Col lg={5} className="fixed-preview">
            {renderPreview()}
          </Col>
        </Row>
      )}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title> {t('SIMULATION.ADD_SIMULATION')} </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Container fluid className="wizard">
            <Row>
              <Col xs={12}>
                <div className="stepper">
                  {steps.map((number, index) => (
                    <div key={number} className="step-container">
                      {index < steps.length - 1 && (
                        <div className={`line ${step > number ? 'completed' : ''}`} />
                      )}
                      <div
                        className={`step ${step === number ? 'active' : ''} ${step > number ? 'completed' : ''}`}
                      >
                        {number}
                      </div>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <h3>{t(`SIMULATION.STEP_${step}`)}</h3>
                <Form>{renderStep()}</Form>
                <div className="button-group mt-4 text-center" >
                  {step > 1 && (
                    <Button variant="secondary" onClick={handlePrevious} className="mr-2 btn-prev">
                      {t('SIMULATION.PREVIOUS')}
                    </Button>
                  )}
                  {step < 3 ? (
                    <Button variant="primary" onClick={handleNext}>
                      {t('SIMULATION.NEXT')}
                    </Button>
                  ) : (
                    <Button variant="primary" onClick={handleAddSimulation}>
                      {t('SIMULATION.CREATE')}
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>



      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this simulation?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>Cancel</Button>
          <Button variant="danger" onClick={handleDelete}>Delete</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Simulations;