import React, { useState, useEffect } from 'react';
import { 
  Table, Button, Modal, Form, InputGroup, FormControl, 
  Container, Row, Col, Pagination, Card, Badge, Alert
} from 'react-bootstrap';
import { FaSearch, FaTimes, FaEdit, FaTrash, FaPlus, FaFilter, FaCalendarAlt } from 'react-icons/fa';
import axios from '../../utils/axios';

const Payments = () => {
  const [payments, setPayments] = useState([]);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [userSearchResults, setUserSearchResults] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newPayment, setNewPayment] = useState({ 
    status: 'paid', 
    period_end: '', 
    amount: '', 
    plan_type: '',
    currency: 'USD',
    method: 'manually'
  });
  const [editPayment, setEditPayment] = useState(null);
  const [errors, setErrors] = useState({});
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardStats, setDashboardStats] = useState({
    totalPayments: 0,
    activeSubscriptions: 0,
    totalRevenueDZD: 0,
    totalRevenueUSD: 0,
  });
  // Filter states
  const [filterStatus, setFilterStatus] = useState('paid'); // Default to 'paid'
  const [filterPeriodEndRange, setFilterPeriodEndRange] = useState({ startDate: '', endDate: '' });

  useEffect(() => {
    fetchPayments();
    fetchDashboardStats();
  }, [page, limit, searchQuery, filterStatus, filterPeriodEndRange]);

  const fetchDashboardStats = async () => {
    try {
      const response = await axios.get('/payments/stats');
      setDashboardStats(response.data);
    } catch (error) {
      console.error("Error fetching dashboard stats", error);
    }
  };

  const fetchPayments = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('/payments/all-payments', {
        params: { 
          search: searchQuery, 
          page, 
          limit,
          status: filterStatus !== 'all' ? filterStatus : undefined,
          periodEndStartDate: filterPeriodEndRange.startDate || undefined,
          periodEndEndDate: filterPeriodEndRange.endDate || undefined
        }
      });
      setPayments(response.data.payments);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching payments", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchUser = async () => {
    if (!searchQuery || searchQuery.length < 2) return;
    try {
      const response = await axios.get(`/user/search`, { params: { search: searchQuery } });
      setUserSearchResults(response.data);
    } catch (error) {
      console.error("Error searching users", error);
    }
  };

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    setUserSearchResults([]);
    setSearchQuery(user.email);
  };

  const handleChange = (e, isEdit = false) => {
    const { name, value } = e.target;
    let formattedValue = value;

    if (name === 'period_end' && value) {
      formattedValue = new Date(value).toISOString().split('T')[0];
    }

    if (isEdit) {
      setEditPayment({ ...editPayment, [name]: formattedValue });
    } else {
      setNewPayment({ ...newPayment, [name]: formattedValue });
    }
    setErrors({ ...errors, [name]: '' });
  };

  const validateForm = (payment) => {
    const newErrors = {};
    if (!selectedUser && !payment.userId) newErrors.user = 'Please select a user';
    if (!payment.period_end) newErrors.period_end = 'Period end date is required';
    if (!payment.plan_type) newErrors.plan_type = 'Plan type is required';
    if (!payment.amount) newErrors.amount = 'Amount is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAddPayment = async (e) => {
    e.preventDefault();
    if (!validateForm(newPayment)) return;

    try {
      setIsLoading(true);
      const response = await axios.post('/payments/add-payment', { 
        ...newPayment, 
        userId: selectedUser.id,
        userEmail: selectedUser.email 
      });
      setPayments([...payments, response.data]);
      setShowPaymentModal(false);
      resetForm();
      fetchDashboardStats();
    } catch (error) {
      setErrors({ general: error.response?.data?.message || "Error adding payment" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditPayment = (payment) => {
    setEditPayment(payment);
    setShowEditModal(true);
  };

  const handleUpdatePayment = async (e) => {
    e.preventDefault();
    if (!validateForm(editPayment)) return;

    try {
      setIsLoading(true);
      await axios.put(`/payments/payment/${editPayment.id}`, editPayment);
      setPayments(payments.map(p => p.id === editPayment.id ? editPayment : p));
      setShowEditModal(false);
      fetchDashboardStats();
    } catch (error) {
      setErrors({ general: error.response?.data?.message || "Error updating payment" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDisablePayment = async (paymentId) => {
    try {
      await axios.put(`/payments/cancel-payment/${paymentId}`);
      setPayments(payments.map(p => p.id === paymentId ? { ...p, status: 'cancelled' } : p));
      fetchDashboardStats();
    } catch (error) {
      console.error("Error cancelling payment", error);
    }
  };

  const resetForm = () => {
    setNewPayment({ 
      status: 'paid', 
      period_end: '', 
      amount: '', 
      plan_type: '',
      currency: 'USD',
      method: 'manually'
    });
    setSelectedUser(null);
    setSearchQuery('');
    setErrors({});
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(parseInt(newLimit));
    setPage(1);
  };

  const handleResetFilters = () => {
    setFilterStatus('paid'); // Reset to default 'paid'
    setFilterPeriodEndRange({ startDate: '', endDate: '' });
    setSearchQuery('');
    setPage(1);
  };

  const formatDateDDMMYYYY = (dateString) => {
    if (!dateString) return '-';
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const PaginationControls = () => {
    if (totalPages <= 1) return null;

    return (
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <span className="me-2">Show</span>
          <Form.Select
            size="sm"
            value={limit}
            onChange={(e) => handleLimitChange(e.target.value)}
            style={{ width: '80px' }}
            className="me-2"
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </Form.Select>
          <span>records</span>
        </div>

        <Pagination className="mb-0">
          <Pagination.First onClick={() => handlePageChange(1)} disabled={page === 1} />
          <Pagination.Prev onClick={() => handlePageChange(page - 1)} disabled={page === 1} />
          {page > 2 && <Pagination.Item onClick={() => handlePageChange(1)}>1</Pagination.Item>}
          {page > 3 && <Pagination.Ellipsis disabled />}
          {page > 1 && <Pagination.Item onClick={() => handlePageChange(page - 1)}>{page - 1}</Pagination.Item>}
          <Pagination.Item active>{page}</Pagination.Item>
          {page < totalPages && <Pagination.Item onClick={() => handlePageChange(page + 1)}>{page + 1}</Pagination.Item>}
          {page < totalPages - 2 && <Pagination.Ellipsis disabled />}
          {page < totalPages - 1 && <Pagination.Item onClick={() => handlePageChange(totalPages)}>{totalPages}</Pagination.Item>}
          <Pagination.Next onClick={() => handlePageChange(page + 1)} disabled={page === totalPages} />
          <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={page === totalPages} />
        </Pagination>
      </div>
    );
  };

  return (
    <Container fluid>
      <Row className="mb-4">
        <Col>
          <h1>Payments Management</h1>
        </Col>
      </Row>

      {/* Dashboard Stats */}
      <Row className="mb-4">
        <Col md={3}><Card><Card.Body><h5>Total Payments</h5><h2>{dashboardStats.totalPayments}</h2></Card.Body></Card></Col>
        <Col md={3}><Card className="bg-success text-white"><Card.Body><h5>Active Subscriptions Now</h5><h2>{dashboardStats.activeSubscriptions}</h2></Card.Body></Card></Col>
        <Col md={3}><Card><Card.Body><h5>Total Revenue DZD</h5><h2>DZD{dashboardStats.totalRevenueDZD.toLocaleString()}</h2></Card.Body></Card></Col>
        <Col md={3}><Card className="bg-warning"><Card.Body><h5>Total Revenue USD</h5><h2>${dashboardStats.totalRevenueUSD.toFixed(2)}</h2></Card.Body></Card></Col>
      </Row>

      {/* Filters Section */}
      <Card className="mb-4 shadow-sm">
        <Card.Body>
          <Row className="align-items-center mb-3">
            <Col>
              <h5 className="mb-0">Filters</h5>
            </Col>
            <Col className="text-end">
              <Button
                variant="link"
                className="p-0 text-decoration-none"
                onClick={handleResetFilters}
              >
                Clear all filters
              </Button>
            </Col>
          </Row>

          <Row className="g-3">
            <Col md={4} lg={3}>
              <InputGroup>
                <InputGroup.Text><FaSearch /></InputGroup.Text>
                <FormControl
                  placeholder="Search by user email..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                {searchQuery && (
                  <Button variant="outline-secondary" onClick={() => setSearchQuery('')}><FaTimes /></Button>
                )}
              </InputGroup>
            </Col>
            <Col md={4} lg={2}>
              <Form.Select
                value={filterStatus}
                onChange={(e) => setFilterStatus(e.target.value)}
              >
                <option value="all">All Status</option>
                <option value="paid">Paid</option>
                <option value="pending">Pending</option>
                <option value="cancelled">Cancelled</option>
              </Form.Select>
            </Col>
            <Col md={6} lg={4}>
              <Form.Group>
                <Form.Label className="d-flex align-items-center">
                  <FaCalendarAlt className="me-1" /> Period End Date Range
                </Form.Label>
                <Row>
                  <Col>
                    <Form.Control
                      type="date"
                      value={filterPeriodEndRange.startDate}
                      onChange={(e) => setFilterPeriodEndRange({ ...filterPeriodEndRange, startDate: e.target.value })}
                      placeholder="From"
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="date"
                      value={filterPeriodEndRange.endDate}
                      onChange={(e) => setFilterPeriodEndRange({ ...filterPeriodEndRange, endDate: e.target.value })}
                      placeholder="To"
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Col>
            <Col md={4} lg={3} className="d-flex justify-content-end align-items-end">
              <Button variant="primary" onClick={() => setShowPaymentModal(true)}>
                <FaPlus /> Add Payment
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {/* Payments Table */}
      <Card>
        <Card.Header className="bg-white py-3">
          <PaginationControls />
        </Card.Header>
        <Card.Body>
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <Table hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Status</th>
                  <th>User</th>
                  <th>Amount</th>
                  <th>Plan</th>
                  <th>Period End</th>
                  <th>Method</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {payments.map((payment) => (
                  <tr key={payment.id}>
                    <td>{payment.id}</td>
                    <td><Badge bg={payment.status === 'paid' ? 'success' : 'danger'}>{payment.status}</Badge></td>
                    <td>{payment.userEmail}</td>
                    <td>{payment.currency} {payment.amount}</td>
                    <td>{payment.plan_type}</td>
                    <td>{formatDateDDMMYYYY(payment.period_end)}</td>
                    <td>{payment.method}</td>
                    <td>
                      <Button variant="outline-primary" size="sm" onClick={() => handleEditPayment(payment)}><FaEdit /></Button>
                      <Button variant="outline-danger" size="sm" onClick={() => handleDisablePayment(payment.id)}><FaTrash /></Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Card.Body>
        <Card.Footer className="d-flex justify-content-center py-3">
          <PaginationControls />
        </Card.Footer>
      </Card>

      {/* Add Payment Modal */}
      <Modal show={showPaymentModal} onHide={() => setShowPaymentModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddPayment}>
            <Form.Group className="mb-3">
              <Form.Label>User Email</Form.Label>
              <InputGroup>
                <FormControl
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    handleSearchUser();
                  }}
                  placeholder="Search user by email"
                  isInvalid={!!errors.user}
                />
                {userSearchResults.length > 0 && (
                  <div className="position-absolute w-100 border rounded bg-white shadow-sm" style={{ zIndex: 1000 }}>
                    {userSearchResults.map((user) => (
                      <div 
                        key={user.id} 
                        className="p-2 border-bottom cursor-pointer hover-bg-light"
                        onClick={() => handleUserSelect(user)}
                      >
                        {user.email}
                      </div>
                    ))}
                  </div>
                )}
              </InputGroup>
              {errors.user && <Form.Text className="text-danger">{errors.user}</Form.Text>}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Plan Type</Form.Label>
              <Form.Select name="plan_type" value={newPayment.plan_type} onChange={handleChange} isInvalid={!!errors.plan_type}>
                <option value="">Select Plan</option>
                <option value="monthly">Monthly</option>
                <option value="yearly">Yearly</option>
                <option value="test">Test Period</option>
              </Form.Select>
              {errors.plan_type && <Form.Text className="text-danger">{errors.plan_type}</Form.Text>}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Period End</Form.Label>
              <Form.Control
                type="date"
                name="period_end"
                value={newPayment.period_end}
                onChange={handleChange}
                isInvalid={!!errors.period_end}
              />
              {errors.period_end && <Form.Text className="text-danger">{errors.period_end}</Form.Text>}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                name="amount"
                value={newPayment.amount}
                onChange={handleChange}
                isInvalid={!!errors.amount}
              />
              {errors.amount && <Form.Text className="text-danger">{errors.amount}</Form.Text>}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Currency</Form.Label>
              <Form.Select name="currency" value={newPayment.currency} onChange={handleChange}>
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
                <option value="DZD">DZD</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Method</Form.Label>
              <Form.Control value="manually" disabled />
            </Form.Group>
            {errors.general && <Alert variant="danger">{errors.general}</Alert>}
            <Button variant="primary" type="submit" disabled={isLoading}>
              {isLoading ? 'Adding...' : 'Add Payment'}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Edit Payment Modal */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editPayment && (
            <Form onSubmit={handleUpdatePayment}>
              <Form.Group className="mb-3">
                <Form.Label>User Email</Form.Label>
                <Form.Control value={editPayment.userEmail} disabled />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Plan Type</Form.Label>
                <Form.Select name="plan_type" value={editPayment.plan_type} onChange={(e) => handleChange(e, true)} isInvalid={!!errors.plan_type}>
                  <option value="monthly">Monthly</option>
                  <option value="yearly">Yearly</option>
                  <option value="test">Test Period</option>
                </Form.Select>
                {errors.plan_type && <Form.Text className="text-danger">{errors.plan_type}</Form.Text>}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Period End</Form.Label>
                <Form.Control
                  type="date"
                  name="period_end"
                  value={editPayment.period_end ? editPayment.period_end.split('T')[0] : ''}
                  onChange={(e) => handleChange(e, true)}
                  isInvalid={!!errors.period_end}
                />
                {errors.period_end && <Form.Text className="text-danger">{errors.period_end}</Form.Text>}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  type="number"
                  name="amount"
                  value={editPayment.amount}
                  onChange={(e) => handleChange(e, true)}
                  isInvalid={!!errors.amount}
                />
                {errors.amount && <Form.Text className="text-danger">{errors.amount}</Form.Text>}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Currency</Form.Label>
                <Form.Select name="currency" value={editPayment.currency} onChange={(e) => handleChange(e, true)}>
                  <option value="USD">USD</option>
                  <option value="EUR">EUR</option>
                  <option value="DZD">DZD</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Method</Form.Label>
                <Form.Control value={editPayment.method} disabled />
              </Form.Group>
              {errors.general && <Alert variant="danger">{errors.general}</Alert>}
              <Button variant="primary" type="submit" disabled={isLoading}>
                {isLoading ? 'Updating...' : 'Update Payment'}
              </Button>
            </Form>
          )}
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Payments;