import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Dashboard = () => {
  const { t } = useTranslation();

  const featureVideos = [
    {
      title: t("How to pay for your plan"),
      url: "https://www.youtube.com/embed/9k9jU04_MLw",
      description: t("This video tutorial guides you through the available payment methods, helping you complete your purchase and start using the COD pricing platform.")
    },

    {
      title: t("How to input your team and your costs"),
      url: "https://www.youtube.com/embed/0GnYRiZUGsk",
      description: t("Learn how to input your team and your costs. This video tutorial shows you how to add team members, set their salaries, and add additional costs such as fixed costs (e.g. office rent, software subscriptions, etc.), tools subscription and more.")
    },
    {
      title: t("Method of work and tracking your expenses with COD pricing."),
      url: "https://www.youtube.com/embed/OEEFCDnLRe0",
      description: t("Method of work how to use simulation and tracking your expenses with COD pricing. This video tutorial explains how we work with COD pricing and how it can help you track your expenses.")
    }
  ];

  /**
   * Render a list of YouTube videos.
   *
   * This function takes a list of objects with the following properties:
   * - title: The title of the video.
   * - url: The URL of the video.
   * - description: A description of the video.
   *
   * It returns a list of React components, each containing an iframe with the YouTube video,
   * a title, and a description.
   *
   * @param {Object[]} videos - The list of videos to render.
   * @returns {ReactNode[]} - The rendered list of videos.
   */
  const renderVideos = (videos) => {
    return videos.map((video, index) => (
      <Col key={index} md={6} className="mb-4">
        <Card>
          <Card.Body>
            <Card.Title>{video.title}</Card.Title>
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                className="embed-responsive-item"
                src={video.url}
                title={video.title}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <Card.Text className='video-description'>{video.description}</Card.Text>
          </Card.Body>
        </Card>
      </Col>
    ));
  };

  return (
    <Container className="plans-container">
      <h2 className='mt-3' >{t('Learn About the Features')}</h2>
      <section className="mt-3">
        <Row>
          {renderVideos(featureVideos)}
        </Row>
      </section>
    </Container>
  );
};

export default Dashboard;