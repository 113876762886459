import React, { useState } from 'react';
import axios from '../../utils/axios';
import { Button, Form, Container, Alert } from 'react-bootstrap';

const WebhookTester = () => {
  const [formData, setFormData] = useState({
    testStatus: 'paid',        // Default status
    order_id: '',               // New field for order ID
    additional_data: '',
    amount:"3.99"         // New field for additional information
  });

  const [responseMessage, setResponseMessage] = useState(null);
  const [error, setError] = useState(null);

  // Handle input changes for dynamic form updating
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Function to handle sending test webhook
  const handleTestWebhook = async () => {
    try {
      // Make POST request to backend with form data
      const response = await axios.post('/cryptomus-webhook-test', formData);
      setResponseMessage('Webhook sent successfully: ' + JSON.stringify(response.data));
      setError(null);
    } catch (err) {
      console.error('Error testing webhook:', err.response?.data || err.message);
      setError('Failed to send test webhook');
      setResponseMessage(null);
    }
  };

  return (
    <Container>
      <h2>Test Webhook</h2>
      <Form>
        <Form.Group controlId="webhookStatus">
          <Form.Label>Select Webhook Status</Form.Label>
          <Form.Control as="select" name="testStatus" value={formData.testStatus} onChange={handleInputChange}>
            <option value="paid">Paid</option>
            <option value="paid_over">Paid Over</option>
            <option value="wrong_amount">Wrong Amount</option>
            <option value="fail">Fail</option>
            <option value="cancel">Cancel</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="orderId">
          <Form.Label>Order ID</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Order ID"
            name="order_id"
            value={formData.order_id}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group controlId="additional_data">
          <Form.Label>Additional Information</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Additional Info"
            name="additional_data"
            value={formData.additional_data}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Button variant="primary" onClick={handleTestWebhook}>
          Send Test Webhook
        </Button>
      </Form>
      {responseMessage && <Alert variant="success" className="mt-3">{responseMessage}</Alert>}
      {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
    </Container>
  );
};

export default WebhookTester;